/**
* prettyPre
* - Extends jQuery with prettyPre functionality
* - removes tabs/spaces in html pre tags
*/
export default $.fn.prettyPre = function() {
	const init = function() {
		this.each(function(){
			const $_self 			= $(this),
				ignoreExpression 	= /\s/,
				text 				= $_self.html(),
				parts 				= text.split( "\n" );

			let spaceCount 			= 0,
				currentCharacter 	= text.substring( 0, 1 ),
				formattedText 		= "";

			while ( ignoreExpression.test( currentCharacter ) ) {
				currentCharacter = text.substring( ++spaceCount, spaceCount + 1 );
			}

			for ( var i = 0; i < parts.length; i++ ) {
				formattedText += parts[i].substring( spaceCount ) + ( i == parts.length - 1 ? "" : "\n" );
			}
			$_self.html(formattedText);
		});
	};
	return init.apply(this);
};
