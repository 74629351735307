export default class InitDropdownMap {
	constructor() {
		this.cache = {
      $map: $('#campus-map'),
      $mapDropdown: $('.map__dropdown *'),
      $dropdown: $('.map__dropdown').find('.dropdown'),
      $dropdownList: $('.map__dropdown').find('.dropdown ul'),
      $dropdownItem: $('.map__dropdown').find('.dropdown ul li'),
			$dropdownSearchItem: $('.map__search').find('ul li'),
      $menu: $('.map__menu'),
      $menuToggle: $('.map__container').find('.toggle'),
		}
		if(this.cache.$dropdown.length){
			this.setListeners()
		}
	}

  setListeners(){
    let _this = this
    this.cache.$menuToggle.on('click', function(){
      let $self = $(this)
      _this.toggleMenu($self)
    })
  }

  toggleDropdown($self){
    this.cache.$dropdown.not($self).removeClass('is-active')
    this.cache.$dropdownList.not($self.find('ul')).removeClass('is-active')
    $self.toggleClass('is-active')
    $self.find('ul').toggleClass('is-active')
  }

  onItemSelect($self){
		if(!$self.hasClass('is-active')){
			$self.addClass('is-active')
			$self.find('input[type=checkbox]').prop('checked', true)
		} else {
			$self.removeClass('is-active')
			$self.find('input[type=checkbox]').prop('checked', false)
		}
  }

  toggleMenu($self){
    $self.toggleClass('is-active')
    this.cache.$menu.toggleClass('is-hidden')
    this.cache.$map.toggleClass('is-expanded')
  }


}
