export default class InitDropdown {
	constructor() {
		this.cache = {
			$input:	$('.input'),
		}
		if(this.cache.$input.length){
			this.setListeners()
		}
	}

  setListeners(){
    let _this = this
    this.cache.$input.off('keyup').on('keyup', function(){
      let $self = $(this)
      _this.filterResults($self)
    })
		this.cache.$input.closest('form').find('.ico--f-close-s').on('click', function(){
			$(this).closest('form').find('.input').val('').trigger('change')
		})
  }

  filterResults($self){
    var value = $self.val().toLowerCase()
    this.cache.$input.nextAll('ul').find('li').not('li.no-results').filter(function(){
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    })
    if(this.cache.$input.nextAll('ul').children(':visible').not('li.no-results').length === 0){
      this.cache.$input.nextAll('ul').find('li.no-results').show()
    } else {
      this.cache.$input.nextAll('ul').find('li.no-results').hide()
    }
  }
}
