/**
* Main State Object
* @type {obj}
*/
export const store = {
	defaultClasses: {
		active: 	"is-active",
		disabled: 	"is-disabled",
		hidden:		"is-hidden",
		success:	"is-success",
		focus:		"is-focus",
		error:		"is-error",
		sticky:		"is-sticky",
        hover:      "is-hover",
		initialise:	"is-initialised"
	},
	breakPoints: {
		minimum:	375,
		small:		544,
		medium: 	769,
		large:		992,
		maximum:	1230
	},
	cache: {
		$window:	$(window),
		$document: 	$(document),
		$html: 		$("html"),
		$body: 		$("body"),
        $bodyWrap:  $(".body-wrap"),
		$pre:		$(".js-pre")
	}
};
