import { store } from '../store/store.js';

export default class InitFilter {

	constructor() {
		if ($('.lookup__item').length === 0)
			return

		this.buildResultHTML()
		this.setupAZButtonState()
		this.clickAZButton()
		this.setupSearch()
		this.viewAll()
		showNumberOfResults()

		if($('fieldset[data-name="filterCheckboxes"]').length){
			this.filterCheckbox()
		}
	}

	buildResultHTML() {
		if (typeof lookupItems === 'undefined')
			return

		lookupItems.sort((a, b) => (a.asset_name > b.asset_name) ? 1 : ((b.asset_name > a.asset_name) ? -1 : 0));
		for (let item of lookupItems) {
			let letter = item.asset_name[0]
			$(`.lookup__item[data-filter=${letter}] ul`).append(`<li data-value="${item.asset_name}" data-type="${item.asset_type}"><a href="${item.asset_url}" title="${item.asset_name}">${item.asset_name}</a></li>`)
		}
	}

	setupAZButtonState() {
		$('.lookup__item').each(function (index) {

			let foundListing = $(this).find('li').length
			let letter = $(this).attr('data-filter')

			if (!foundListing)
				$(`button[data-filter=${letter}]`).prop('disabled', true)
			else
				$(this).prepend(`<h5>${letter}</h5>`)
		})
	}

	viewAll() {
		$('#lookup__view-all').click(function () {
			$(this).hide()
			reset()
		})
	}

	clickAZButton() {
		$('button.button--filter').click(function () {

			if ($(this).hasClass('is-active')) {
				$(this).removeClass('is-active')
				$(`.lookup__item, .lookup__item li`).show()
				$('#lookup__view-all').hide()
				reset()
				return null
			}

			$('#filter-search__input').val('')
			let letter = $(this).attr('data-filter')

			$('button.button--filter').removeClass('is-active')
			$(this).addClass('is-active')

			$('.lookup__item').hide()
			$('#lookup__view-all').show()
			$('.search-bar').hide()
			$(`.lookup__item[data-filter=${letter}], .lookup__item[data-filter=${letter}] li`).show()
			showNumberOfResults()
		})
	}

	setupSearch() {
		let _this = this
		$('#filter-search__input').on("keyup", function () {

			if ($(this).val().length > 0) {
				$("#filter-search__reset, .search-clear").show()
				$("#results-search__submit").hide()

			} else {
				$("#filter-search__reset, .search-clear").hide()
				$("#results-search__submit").show()
			}
			$('button.button--filter').removeClass('is-active')

			let searchValue = $(this).val().toLocaleLowerCase()

			$('.lookup__item, .lookup__item li').hide()

			$(".lookup__item li").each(function () {
				let linkValue = $(this).data('value').toLowerCase()
				if (linkValue.includes(searchValue)) {
					$(this).show()
					$(this).closest('.lookup__item').show()
				}
			})
			showNumberOfResults()
		})

		$("#filter-search__reset").click(function () {
			$(this).hide()
			reset()
		})
	}

	filterCheckbox(){
		$('fieldset[data-name="filterCheckboxes"]').find('input[type=checkbox]').on('click', function(){
			if($('fieldset[data-name="filterCheckboxes"]').find('input[type=checkbox]:checked').length == $('fieldset[data-name="filterCheckboxes"]').find('input[type=checkbox]').length){
				$('#lookup__view-all').hide()
				reset()
			} else {
				let filter = $('fieldset[data-name="filterCheckboxes"]').find('input[type=checkbox]:checked').attr('data-type')
				$('.lookup__items').find('.lookup__item li:visible').each(function(){
					if($(this).attr('data-type') == filter){
						$(this).closest('.lookup__item').show()
						$(this).show()
					} else {
						$(this).closest('.lookup__item').hide()
						$(this).hide()
					}
				})
				showNumberOfResults()
			}
		})
	}
}

function reset() {
	$(this).hide()
	$('button.button--filter').removeClass('is-active')
	$(".search-clear, #filter-search__reset").hide()
	$(`.lookup__item, .lookup__item li`).show()
	$("#results-search__submit").show()
	$('#filter-search__input').val('')
	$('.search-bar').show()
	if($('fieldset[data-name="filterCheckboxes"]').length){
		$('fieldset[data-name="filterCheckboxes"]').find('input[type=checkbox]').each(function(){
			$(this).prop('checked', true)
		})
	}

	showNumberOfResults()
}

function showNumberOfResults() {
	let filteredResults = $('.lookup__item a:visible').length
	let totalResults = $('.lookup__item a').length

	let searchVal = $('#filter-search__input').val()

	let wording = "items were"

	let searchEndText = "clinics and services";

	if(typeof searchText !== "undefined")
		searchEndText = searchText

	if(filteredResults === 1)
		wording = "item was"

	if($('#filter-search__input').length && searchVal.length > 0)
		$('.lookup__results').html(`<strong>${filteredResults}</strong> ${wording} found for the search term <strong>‘${searchVal}’</strong>`)
	else if($('button.button--filter.is-active').length > 0)
		$('.lookup__results').html(`<strong>${filteredResults}</strong> ${wording} found for <strong>‘${$('button.button--filter.is-active').text()}’</strong>`)
	else
		$('.lookup__results').html("<p>" + "<b>" + filteredResults + "</b>" + " of " + totalResults + " " + searchEndText)

	if(filteredResults >= 50)
		$('.back-to-top').show()
	else
		$('.back-to-top').hide()
}
