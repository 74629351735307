import { store } from '../store/store.js'
/**
 * InitStackableTabs
 */
export default class InitStackableTabs {
  constructor() {
    /**
     * Initalise Class Functions
     */
    if ($('.stackable-tabs').length) {
      this.handleOnLoad()
      this.initDataValues()
      this.setListeners()
    }
  }

  handleOnLoad() {
    $('.stackable-tabs--item').first().find('div[role=button]').addClass('is-active')
    $('.stackable-tabs--item')
      .first()
      .find('div[role=button] .icon--plusminus')
      .addClass('is-active')

    if ($(window).width() < 768) {
      $('.stackable-tabs--item').first().find('.stackable-tabs--body').slideDown()
    } else {
      $('.stackable-tabs--item').first().find('.stackable-tabs--body').addClass('is-active')
    }
  }

  initDataValues() {
    $('.stackable-tabs--item')
      .find('div[role=button]')
      .each(function () {
        let value = $(this).text()
        $(this).attr('data-tag', value.trim())
      })

    $('.stackable-tabs--body').each(function () {
      let value = $(this).find('h4').text()
      $(this).attr('data-tag', value.trim())
    })
  }

  setListeners() {
    $('.stackable-tabs--item')
      .find('div[role=button]')
      .on('click', function () {
        if ($(this).hasClass('is-active')) return

        $('.stackable-tabs--item').find('div[role=button]').removeClass('is-active')
        $('.stackable-tabs--item').find('.icon--plusminus').removeClass('is-active')

        $(this).addClass('is-active')
        $(this).find('.icon--plusminus').addClass('is-active')

        // let buttonTag = $(this).attr('data-tag')
        let dataTag = $(this).nextAll('.stackable-tabs--body').first().attr('data-tag')
        if ($(window).width() < 768) {
          $(`.stackable-tabs--body[data-tag='${dataTag}']`).slideDown().addClass('is-active')
          $(`.stackable-tabs--body[data-tag!='${dataTag}']`).slideUp().removeClass('is-active')
        } else {
          $(`.stackable-tabs--body[data-tag!='${dataTag}']`)
            .removeAttr('style')
            .removeClass('is-active')
          $(`.stackable-tabs--body[data-tag='${dataTag}']`)
            .removeAttr('style')
            .addClass('is-active')
        }
      })
  }
}
