// import { store } from '../store/store';

/**
 * InitModernizr
 */
export default class InitModernizr {

	constructor(){

		/**
		 * Initalise Class Functions
		 */

	}

}
