export default class InitSteps {
  constructor() {
    this.cache = {
      $steps: $('[data-type="steps"]'),
      $step: $('[data-type="steps"] [data-type="step"]'),
      $stepButton: $('[data-type="steps"] [data-type="step"] button'),
      $stepDropdownItem: $('[data-type="steps"] [data-type="step"] .dropdown').find('li'),
    }
    if (this.cache.$steps.length) {
      this.initSteps()
      this.setListeners()
    }
  }

  initSteps() {
    $('[data-type="steps"] [data-type="step"]').each(function () {
      if ($(this).attr('data-step') == 0) {
        $(this).show()
      } else {
        $(this).hide()
      }
    })
  }

  setListeners() {
    let _this = this
    $('[data-type="steps"] [data-type="step"] button')
      .add('[data-type="steps"] [data-type="step"] li')
      .on('click', function () {
        if ($(this).data('next') != undefined) {
          let nextStep = $(this).attr('data-next')
          $(this)
            .closest('[data-type="step"]')
            .nextAll('[data-type="step"]')
            .each(function () {
              $(this).find('button').removeClass('is-inactive is-active')
              if (nextStep == $(this).attr('data-step')) {
                $(this).show()
              } else {
                $(this).hide()
              }
            })
        }
      })
    $('[data-type="steps"] [data-type="step"] .dropdown')
      .find('li')
      .on('click', function () {
        if ($(this).closest('ul').data('next') != undefined) {
          let nextStep = $(this).closest('ul').attr('data-next')
          $(this)
            .closest('[data-type="step"]')
            .nextAll('[data-type="step"]')
            .each(function () {
              $(this).find('button').removeClass('is-inactive is-active')
              if (nextStep == $(this).attr('data-step')) {
                $(this).show()
              } else {
                $(this).hide()
              }
            })
        }
      })
  }
}
