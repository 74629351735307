import { store } from '../store/store.js';
import { debounce } from './helpers.js';

/**
 * InitModernizr
 */
export default class InitEqualHeights {

	constructor(){

		/**
		 * Initalise Class Functions
		 */
         this.initEqualHeightsCalculations();
         this.initEqualHeights();

	}


    initEqualHeightsCalculations(){
        /**
       * Make elements the same height
       */
       $.fn.equalHeights = function(px) {
           var currentTallest = 0;
           $(this).height("auto");
           $(this).each(function(){
               var thisHeight = parseFloat($(this).css('height'));
               if (thisHeight > currentTallest) currentTallest = thisHeight;
           });

           if (!px && Number.prototype.pxToEm) currentTallest = currentTallest.pxToEm(); //use ems unless px is specified

           $(this).css({'height': currentTallest});
           return this;
       };

       /**
       * Make elements the same distance form the top of
       * the viewport the same height
       */
       $.fn.magicEqualHeights = function() {

           var group = [];
           var _this = this;

           _this.each(function(index) {
               group.push(this);
               var nextTop = _this.get(index + 1) ? $(_this.get(index + 1)).offset().top : false;
               var thisTop = $(this).offset().top;

               if (nextTop != thisTop) {
                   $(group).equalHeights();
                   group = [];
               }
           });

           $(group).equalHeights();

           return this;
       };
    }

   initEqualHeights(breakPoint, $els){

        if(typeof $els === 'undefined'){
            $els = [$('.equal-heights')];
        }

        if(typeof breakPoint === 'undefined'){
            breakPoint = 0;
        }

        var setHeight = debounce(function(){
            if(store.cache.$window.width() >= store.breakPoints.medium){
                $.each($els, function(){
                    $(this).magicEqualHeights();
                });
            } else {
                $.each($els, function(){
                    $(this).height('auto');
                });
            }
        }, 10, true);

        setHeight();

        store.cache.$window.on('resize', function(){
            setHeight();
        });
    }

}
