import InitModal from './modal.js'
import InitTongue from './tongue.js'

//import InitForms from './forms/form.js';
//import InitFormButton from './forms/form-button.js';
//import InitFormCounter from './forms/form-counter.js';
//import InitFormDropdown from './forms/form-dropdown.js';
//import InitFormInput from './forms/form-input.js';
//import InitSlider from './forms/form-slider.js';

/**
 * InitPopup
 */
export default class InitPopup {
  constructor(tongue = '') {
    console.log('popup initialised')

    let marketoID = this.getUrlParameter('marketoid')

    marketoID = 3130
    this.initialiseTongue(marketoID)

    //		if(this.getUrlParameter('popupshown')==='true' || tongue){
    //			if(!marketoID)
    //				marketoID = 3130
    //			this.initialiseTongue(marketoID)
    //		}else{
    //			if(!marketoID)
    //				marketoID = 3129
    //			this.initialiseTongue(marketoID)
    //		}
    console.log(marketoID)
  }

  getiframeCode(marketoID) {
    const loader = `<div class="xmx--loader xmx--loader--spinner" role="status" style="display: block; margin: 50px auto;">
			<span class="visually-hidden">Loading...</span>
			<div class="xmx--loader__container" aria-hidden="true">
				<div class="xmx--loader__layer">
					<div class="xmx--loader__border"></div>
					<div class="xmx--loader__clipper xmx--loader__clipper--left">
						<div class="xmx--loader__circle"></div>
					</div>
					<div class="xmx--loader__patch">
						<div class="xmx--loader__circle"></div>
					</div>
					<div class="xmx--loader__clipper xmx--loader__clipper--right">
						<div class="xmx--loader__circle"></div>
					</div>
				</div>
			</div>
		</div>`

    if (process.env.NODE_ENV === 'development')
      return (
        loader +
        `<iframe src="/components/form/iframe.html" id="form-iframe" frameBorder="0" data-hj-allow-iframe=""></iframe>`
      )
    else
      return (
        loader +
        `<iframe src="https://openday.mq.edu.au/iframe.php?marketoid=${marketoID}" id="form-iframe" frameBorder="0" data-hj-allow-iframe=""></iframe>`
      )
  }

  initialiseTongue(marketoID) {
    $('body').append(`<div class="tongue">
            <div class="tongue__button">
                <div class="tongue__button--icon-container">
                        <i class="ico ico--sm ico--f-form" style="display: flex; justify-content: center; align-items: center;"></i>
                        <i class="ico ico--lg ico--f-caret-r" style="display: flex; justify-content: center; align-items: center;"></i>
                </div>
                <span class="collapsible"></span>
            </div>
            <div class="tongue__overlay">
            </div>
            <div class="tongue__form-container">
                <div class="tongue__form-container--header">
                    <h3>Enquire now</h3>
                    <i class="ico ico--sm ico--f-close-s tongue__close"></i>
                </div>
				${this.getiframeCode(marketoID)}
            </div>
        </div>`)

    if (this.getUrlParameter('popupshown') === 'true') new InitTongue(false)
    else new InitTongue()
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=')

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
      }
    }
  }

  initialisePopup(marketoID) {
    $('body').append(`<div class="marketing-modal" style="display: none;">
				<aside class="modal js-modal" id="modal" aria-hidden="true">
					<div class="wrap wrap--padded">
						<div class="modal__container">
							<div class="modal__header">
								<h3>Enquire now</h3>
							</div>
							<div class="modal__body">
							${this.getiframeCode(marketoID)}
							</div>
							<button type="button" class="icon icon--close js-modal-deactivator">
								<span class="visually-hidden">Click to close modal</span>
							</button>
						</div>
					</div>
				</aside>
			</div>`)
    const testModal = new InitModal('.marketing-modal')
    testModal.open(3000)
  }
}
