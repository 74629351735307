import { store } from '../store/store.js';

export default class InitModalSearch {

	constructor() {


        if($('#jobs-form').length > 0) {
            console.log('code aborted for mq health');
            return; //dont run this code on careers mqhealth
        }

		this.handleModal()
        this.clearAll()
        this.showNumberofFilters()
	}

	handleModal() {
        $('.modal-search--refine').click(function(){
            $('.sidenav__search').addClass('is-active')
        })
        $('.sidenav__search .ico--f-close-s').click(function(){
            $('.sidenav__search').removeClass('is-active')
        })
        $('.modal-search__apply button').click(function(){
            $('.sidenav__search').removeClass('is-active')
        })
	}

    clearAll(){
        let _this = this
        $('.modal-search__nav .clear-all').click(function(){
            $('.sidenav__search--filters .form__checkbox input:checkbox:checked').prop('checked', false);
            $('.modal-search--applied-filters').removeClass('is-active')
        })
    }

    showNumberofFilters(){
        $('.form__checkbox input').click(function(){
            var checked = $('.sidenav__search--filters .form__checkbox input:checkbox:checked').length
            if(checked > 0){
                $('.modal-search--applied-filters').html(checked)
                $('.modal-search--applied-filters').addClass('is-active')
            } else {
                $('.modal-search--applied-filters').removeClass('is-active')
            }
        })
    }
}
