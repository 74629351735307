import { store } from '../store/store.js';
//------------------
// InitTextOverflow
//------------------
export default class InitTextOverflow{

	constructor(){

        this.cache = {
            $container: $('.sub__container'),
            $textHeight: $('.sub__container').height(),
            $header: $('.sub__container h3'),
            $navHeader: $('.nav__back').find('span'),
            $navTextHeight: $('.nav__back').outerHeight(),
        };

		this.handleTextOverflow();
        this.handleResize();
	}

    //------------------------------------------------------------
    // handleTextOverflow - adds ellipsis when text exceeds limit
    //------------------------------------------------------------

    handleTextOverflow(){
        this.cache.$header.each(function(index, self){
            const $_self = $(self);
            while($_self.outerHeight()>this.cache.$textHeight){
                $_self.text(function(index, text){
                    return text.replace(/\W*\s(\S)*$/, '...');
                });
            }
        }.bind(this));
    }

    handleNavTextOverflow(){
        this.cache.$navHeader.each(function(index, self){
            const $_self = $(self);
            while($_self.outerHeight()>this.cache.$navTextHeight){
                $_self.text(function(index, text){
                    return text.replace(/\W*\s(\S)*$/, '...');
                });
            }
        }.bind(this));
    }

    handleResize() {
        $(window).on('resize', function() {
            this.handleTextOverflow();
            this.handleNavTextOverflow();
        }.bind(this));
    }
}
