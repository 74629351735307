export default class InitVideoGallery {
  constructor() {
    this.cache = {
      $video: $('.video'),
      youtubeIdLength: 11,
      vimeoIdLength: 9,
    }

    if (this.cache.$video.length) {
      this.initVideo()
    }
  }

  initVideo() {
    let _this = this
    $('.video').each(function () {
      // Gets videoID
      let videoID = ''
      let video = $(this)
      // Sets listeners
      _this.setListeners(video)
      const iframe_src = $(this).find('iframe').attr('src')
      $(this).append(
        "<div class='loader loader__skeleton--video' role='status'>\
			<svg  width='50' height='50' viewBox='-50 -50 300 300'>\
			<polygon class='triangle' stroke-linejoin='round' points='100,0 0,200 200,200'/>\
			</svg>\
		</div>"
      )
      if (iframe_src.indexOf('youtube') >= 0) {
        videoID = iframe_src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop()
      } else if (iframe_src.indexOf('vimeo') >= 0) {
        videoID = iframe_src.match(/player\.vimeo\.com.*(\?v=|\/video\/)(.{9})/).pop()
      }

      // Sets video thumbnail
      if (
        videoID.length === _this.cache.youtubeIdLength &&
        video.find('.video--wrap').length === 0
      ) {
        _this.handleYouTubeThumbnail(video, videoID)
      } else if (videoID.length === _this.cache.vimeoIdLength) {
        _this.handleVimeoThumbnail(video)
      }

      // Removes skeleton loader once iframe has loaded
      $(this)
        .find('iframe')
        .on('load', function () {
          video.find('.loader__skeleton--video').fadeOut(function () {
            $(this).remove()
          })
        })
    })
  }

  // Removes UI overlay on click
  setListeners(video) {
    video.on('click', function () {
      $(this).find('.video--wrap').fadeOut()
      $(this).find('span').fadeOut()
      $(this).find('iframe')[0].src += '&autoplay=1'
    })
  }

  handleYouTubeThumbnail(video, videoID) {
    console.log('Youtube JSON', 'http://img.youtube.com/vi/' + videoID + '/0.jpg')
    let src = 'http://img.youtube.com/vi/' + videoID + '/0.jpg'
    let videoThumbnail = $(
      `<div class="video--wrap"><img class="video--thumbnail" src=${src}></div>`
    )
    video.append(videoThumbnail)
  }

  handleVimeoThumbnail(video) {
    const vimeo_link = video.find('iframe').attr('src')
    let vimeoAPICall = `https://vimeo.com/api/oembed.json?url=${vimeo_link}`
    let vimeoResponse = $.getJSON(vimeoAPICall, {}).done(function (data) {
      return data
    })
    vimeoResponse.then(function () {
      console.log('Vimeo JSON', vimeoResponse.responseJSON)
      let src = vimeoResponse.responseJSON.thumbnail_url.replace(/_295x166$/, '_800')
      let videoThumbnail = $(
        `<div class="video--wrap"><img class="video--thumbnail" src=${src}></div>`
      )
      video.append(videoThumbnail)
    })
  }
}
