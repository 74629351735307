export default class InitButton {
  constructor() {
    this.cache = {
        $buttonSelect:	$('.button--select'),
        $buttonSelection:	$('.button--selection'),
    };
    if(this.cache.$buttonSelect.length){
        this.toggleActiveButton();
    }
    if(this.cache.$buttonSelection.length){
        this.handleButtonSelection();
    }
  }

  toggleActiveButton() {
    $(".button--select").on("click", function () {
      $(this).toggleClass("is-active");
    });
  }

  handleButtonSelection() {
    $(".button--selection .button--select").on("click", function () {
      $(this)
        .closest(".button--selection")
        .find(".button--select")
        .removeClass("is-active, is-inactive")
      $(this).addClass("is-active")
      $(this)
        .closest(".button--selection")
        .find(".button--select")
        .not(this)
        .removeClass("is-active")
        .addClass("is-inactive");
    });
  }
}
