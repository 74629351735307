import { store } from '../store/store.js'
const bodyScrollLock = require('body-scroll-lock')

export default class InitTongue {
  constructor(autoOpen = true) {
    //----------------------------
    // Initialise class functions
    //----------------------------
    this.autoOpen = autoOpen
    this.disableBodyScroll = bodyScrollLock.disableBodyScroll
    this.enableBodyScroll = bodyScrollLock.enableBodyScroll
    this.formContainer = document.querySelector('.tongue')

    this.handleClickEvent()
    if (this.autoOpen)
      setTimeout(
        function () {
          this.toggleTongue()
        }.bind(this),
        5000
      )
  }

  //--------------------------------------------------
  // handleTongueState - opens and closes tongue
  //--------------------------------------------------

  handleClickEvent() {
    $('.tongue__button, .tongue__close').click(
      function () {
        this.toggleTongue(true)
      }.bind(this)
    )
  }

  toggleTongue(addID = false) {
    $('.tongue__button').toggleClass(store.defaultClasses.active)
    $('.tongue__form-container').toggleClass(store.defaultClasses.active)
    $('.tongue__overlay').toggleClass(store.defaultClasses.active)
    store.cache.$html.toggleClass('no-scroll')
    store.cache.$body.toggleClass('no-scroll')
    store.cache.$body.toggleClass(store.defaultClasses.disabled)

    if ($('.tongue__button').hasClass(store.defaultClasses.active)) {
      if (addID) $('.tongue').attr('id', 'open-tongue')
      $('.tongue__form-container').attr('aria-hidden', true)
      this.disableBodyScroll(this.formContainer)
    } else {
      if (addID) $('.tongue').attr('id', 'close-tongue')
      $('.tongue__form-container').attr('aria-hidden', false)
      this.enableBodyScroll(this.formContainer)

      //if form completed, remove tongue

      if ($('.tongue').hasClass('form-completed')) $('.tongue').fadeOut()
    }
  }
}
