import { store } from '../store/store.js';

/**
  * Templates - Used to manipulate DOM for Squiz templates
  */
export default class InitTemplates {

	constructor() {

		/**
 		 * Initalise Class Functions
 		 */
        this.moveRightHandNav();
        this.handleListeners();
	}

	/**
 	 * @property {Function} moveRightHandNav - Moves the right hand nav within Squiz
 	 * @returns void
 	 */
    moveRightHandNav(){
		// If no RHS tiles to move, expand main content
		if ($('.js-rhs-tile-container').children().length <= 0) {
			var main = $('.main-content');

			// If no sidenav, expand content to full width
			main.removeClass(function(index, className) {
				// Match all desktop classes
				return (className.match(/(grid__col-md--*)\w/g)).join(' ');
			});
			main.addClass('grid__col-md--12');

			$('#rhc__side').remove();
		} else {
			$('#rhc__side').html($('.js-rhs-tile-container').html());
		}
        $('.js-rhs-tile-container').remove();
    }

    handleListeners(){
        $(window).on('load', function(){
            if(store.cache.$window.width() < 768){
                this.movePageOwner();
            }
        }.bind(this));
    }

    movePageOwner() {
        if ($('#rhc__side').children().length >= 1){
            $('#contact__owner').removeClass('grid');
            $('#contact__owner').removeClass('margin-top--80');
            $('#contact__owner').addClass('margin-top--12');
            $('#contact__owner').addClass('grid__col--12');
            $('#contact__owner').insertAfter('#rhc__side');
        }
    }
}
