export default class InitBarGraph{

	constructor(){
    this.cache = {
        $barGraph:	$('.bar-graph'),
        $bar:	      $('.bar-graph .bar'),
        $barLegendItem:	      $('.bar-graph ~ ul li'),
    }
    if($('.bar-graph').length){
      this.setBarHeight()
      this.appendLegend()
      this.handleHoverState()
    }
	}

  setBarHeight(){
    this.cache.$bar.each(function(){
      let width = $(this).attr('data-percent')
      $(this).css('width', width+'%')
      $(this).append('<span></span>')
    })
  }

  appendLegend(){
    let _this = this
    this.cache.$barGraph.each(function(){
      let $self = $(this)
      $(this).after('<ul></ul>')
      $(this).find('.bar').each(function(index){
        let data = $(this).attr('data-value').replace(/\s+/g, '-').toLowerCase()
        $self.next('ul').append('<li data-value='+ data +'>' + $(this).attr('data-value') + ' (' + $(this).attr('data-percent') + '%)' + '</li>')
      })
    })
  }

  handleHoverState(){
    let _this = this
    this.cache.$barGraph.each(function(){
      let $currentGraph = $(this)
      $(this).find('.bar').on('mouseenter', function(){
        let data = $(this).attr('data-value').replace(/\s+/g, '-').toLowerCase()
        $currentGraph.next('ul').find('li').each(function(){
          if($(this).attr('data-value') == data){
            $(this).addClass('is-hover')
          }
        })
      })
      $(this).find('.bar').on('mouseleave', function(){
        let data = $(this).attr('data-value').replace(/\s+/g, '-').toLowerCase()
        $currentGraph.next('ul').find('li').each(function(){
          if($(this).attr('data-value') == data){
            $(this).removeClass('is-hover')
          }
        })
      })
      $(this).next('ul').find('li').on('mouseenter', function(){
        let $self = $(this)
        $(this).addClass('is-hover')
        $currentGraph.find('.bar').each(function(){
          let data = $(this).attr('data-value').replace(/\s+/g, '-').toLowerCase()
          if($self.attr('data-value') == data){
            $(this).addClass('is-hover')
          }
        })
      })
      $(this).next('ul').find('li').on('mouseleave', function(){
        let $self = $(this)
        $(this).removeClass('is-hover')
        $currentGraph.find('.bar').each(function(){
          let data = $(this).attr('data-value').replace(/\s+/g, '-').toLowerCase()
          if($self.attr('data-value') == data){
            $(this).removeClass('is-hover')
          }
        })
      })
    })
  }
}
