export default class InitChartDonut{

	constructor(){
		// Check if a donut chart exists in the DOM
    if($('.chart--donut').length){
			this.addLabels()
			this.handleHover()
    }
	}

  // initDonut(){
  //   let radius = 15.91549430918954
  //   let numberOfSegments = $('.chart--donut').find('circle.segment').length
  //   let dasharray = (100 / numberOfSegments) + 'px ' + (100 - 100 / numberOfSegments) + 'px'
  //   $('.chart--donut').find('circle.segment').each(function(index){
  //     let totalLength = (100 / numberOfSegments) * index
  //     let initialOffset = 25
  //     let offset = initialOffset
  //     if(index === 0){
  //       offset = initialOffset
  //     } else {
  //       offset = 100 - totalLength + initialOffset
  //     }
  //     $(this).css('stroke-dasharray', dasharray)
  //     $(this).css('stroke-dashoffset', offset)
  //   })
  // }

	handleHover(){
		$('.chart__caption').each(function(index){
			$(this).on('mouseenter', function(){
				$('.chart--donut:not(.to-animate)').find('div[data-type=segment]').eq(index).addClass('is-hover')
				$('.chart--donut.to-animate').addClass('is-animated')
			})
			$(this).on('mouseleave', function(){
				$('.chart--donut:not(.to-animate)').find('div[data-type=segment]').eq(index).removeClass('is-hover')
				$('.chart--donut.to-animate').removeClass('is-animated')
			})
		})
	}

	addLabels(){
		document.querySelectorAll('.chart--donut:not(.to-animate)').forEach((pieEl)=>{
			let totalAngles = 0
			pieEl.querySelectorAll('div[data-type=segment]').forEach((el)=>{
				const percent = parseFloat(el.dataset.percent)
				const angle = percent / 100 * 360
				const desc = document.querySelector('div[data-type=captions]')
				totalAngles += angle
				const actualAngle = totalAngles - (angle / 2)
				this.addDescription(pieEl, desc, el, actualAngle)
			})
		})
	}

	addDescription(pieEl, desc, segmentEl, angle){
		const descriptionEl = document.createElement("div")
		descriptionEl.classList.add("chart__caption","flex")
		descriptionEl.innerHTML = segmentEl.dataset.description
		desc.appendChild(descriptionEl)
		const {height} = pieEl.getBoundingClientRect()
		this.addPositioningBasedOnAngle(descriptionEl, angle, height)
	}

	pointsOnCircle(radius, angle){
		const x = Math.abs(Math.round(radius + radius * Math.sin(Math.PI * 2 * angle / 360)))
		const y = Math.abs(Math.round(-radius + radius * Math.cos(Math.PI * 2 * angle / 360)))
		return [ x, y ]
	}

	addPositioningBasedOnAngle(el, angle, height){
		const radius = height / 2
		const [ x, y ] = this.pointsOnCircle(radius, angle)
		const padding = 16

		if(angle === 0 || (angle > 0 && angle < 91)) {
			el.style.left = x + padding + "px"
			el.style.bottom = height - y + "px"
			return
		}

		if(angle > 91 && angle < 181) {
			el.style.left = x + padding + "px"
			el.style.top = y + "px"
			return
		}

		if(angle > 181 && angle < 271) {
			el.style.right = height - x + padding + "px"
			el.style.top = y + "px"
			return
		}

		if(angle > 271 && angle < 360) {
			el.style.right = height - x + padding + "px"
			el.style.bottom = height - y + "px"
			return
		}
	}
}
