import { store } from '../store/store.js';

export default class InitSidenavSearch {

	constructor() {

		if($('#jobs-form').length > 0) {
            console.log('code aborted for mq health');
            return; //dont run this code on careers mqhealth
        }

		// MQHEALTH
		if($('.health').length){
			this.displayAllResults()
			this.defaultStateListener()
		}

		if($('.sidenav__search').length){
			this.expandFilter()
			this.expandViewMore()
			this.appendFilterTag()
			this.removeFilterTag()
			this.clearAll()
			this.showResults()
		}

		this.previewLength = 6
		this.previewListHeight = "450px"
	}

	displayAllResults() {
		$('#speciality_all:checkbox').prop('checked', true)
		$('#practice-mq-name_all').prop('checked', true)
		$('#practice-primary-suburb_all').prop('checked', true)
		$('#gender_all').prop('checked', true)
	}
	defaultStateListener() {
		let _this = this
		if($('#filter-specialities input:checked').not('#speciality_all').length >= 1){
			$('#speciality_all:checkbox').prop('checked', false)
		} else {
			$('#speciality_all:checkbox').prop('checked', true)
		}

		if($('#filter-clinic input:checked').not('#practice-mq-name_all').length >= 1){
			$('#practice-mq-name_all:checkbox').prop('checked', false)
		} else {
			$('#practice-mq-name_all:checkbox').prop('checked', true)
		}

		if($('#filter-location input:checked').not('#practice-primary-suburb_all').length >= 1){
			$('#practice-primary-suburb_all:checkbox').prop('checked', false)
		} else {
			$('#practice-primary-suburb_all:checkbox').prop('checked', true)
		}

		if($('#filter-gender input:checked').not('#gender_all').length >= 1){
			$('#gender_all:checkbox').prop('checked', false)
		} else {
			$('#gender_all:checkbox').prop('checked', true)
		}
		$('.sidenav__search').find('input').change(function(){
			_this.defaultStateListener()
			var searchTabsPosition = $('.search-tabs').position()
			$('html, body').animate({scrollTop: searchTabsPosition.top}, {duration: 200}, {easing: 'easeInOutCubic'})

			// if(!$(evt.target).is('.search__input input')){
			// 	$('html, body').animate({scrollTop: '430px'}, {duration: 200}, {easing: 'easeInOutCubic'});
			// }
		})
	}

	expandFilter() {
		let _this = this
		// Expand filter category
      $('.sidenav__search--container').click(function(e){
			let listLength = $(this).next("ul").find("li").length
			let viewMore = listLength - _this.previewLength
			// if already active then collapse contained lists
            if ($(this).hasClass('is-active')){
				$(this).next("ul").find("li:last").css("margin-bottom","0")
				$(this).next("ul").find(".sidenav__search--view").html("<span>View more (" + viewMore + ")</span> <i class='ico ico--sm ico--f-chevron-d-s'></i>")
				$(this).next("ul").find(".sidenav__search--view").removeClass('is-expanded')

				$(this).next().css("max-height","0")
				$(this).removeClass('is-active')
				// _this.collapseViewMore()
            } else {
				$(this).next().css("max-height", _this.previewListHeight)
				if($(this).next("ul").find("li").length > _this.previewLength && $(this).next("ul").find(".sidenav__search--view").length === 0){
					_this.appendViewMore($(this))
				}
				$(this).addClass('is-active')
            }
        })
	}

	expandViewMore(){
		let _this = this

		// Expand list of filters to full height
		$(document).on('click', 'div.sidenav__search--view', function(){
			var filterList = $(this).closest("ul")
			let listLength = filterList.find("li").length
			let viewMore = listLength - _this.previewLength

			if($(this).hasClass('is-expanded')){
				$(this).closest("ul").css("max-height", _this.previewListHeight)
				$(this).siblings(":last").css("margin-bottom","0")
				$(this).html("<span>View more (" + viewMore + ")</span> <i class='ico ico--sm ico--f-chevron-d-s'></i>")
				$(this).removeClass('is-expanded')
			} else {
				$(this).closest("ul").css("max-height", filterList.prop('scrollHeight') + $(this).prop('scrollHeight') + "px")
				$(this).siblings(":last").css("margin-bottom","100%")
				$(this).html("<span>View less</span> <i class='ico ico--sm ico--f-chevron-u-s'></i>")
				$(this).addClass('is-expanded')
			}
		})
	}

	// collapseViewMore(){
	// 	$(this).next("ul").css("max-height", _this.previewListHeight)
	// 	$(this).find("li:last").css("margin-bottom","0")
	// 	$(this).next("ul").find(".sidenav__search--view")
	// 	$(this).html("View more (" + viewMore + ") <i class='ico ico--sm ico--f-chevron-d-s'></i>")
	// 	_this.viewMoreExpanded = false
	// }

	// On checkbox input select append filter tag to list of filters
    appendFilterTag(){
      let _this = this
      $('.form__checkbox input, .form__radio input').on('click', function(){
				$(this).next('p').toggleClass('is-checked')
        var filter = $(this).closest('li').find('label p').html()
        var dataFilter = filter.replace(/\s+/g, '-').toLowerCase();
        var item = $("<li data-filter="+dataFilter+"><div class='tag tag--info'><span>"+filter+"</span><button type='button' class='icon icon--close' aria-label='close tag'><span class='visually-hidden'>Click to close alert</span></button></div></li>")
                  .hide()
                  .fadeIn(200, function(){
                    _this.displayClearAll()
                  })

			// Append to list if checked
					if($(this).is(':checked') && $('.sidenav__search--refine ul').find(`li[data-filter='${dataFilter}']`).length === 0){
					  $('.sidenav__search--refine ul').append(item)
					  _this.updateScrollHeight()
						_this.showResults()
						if ($('.sidenav__search--refine ul li').length >= 1){
							_this.addFilterListMargin()
						}
					} else if(!$(this).is(':checked')) {
					// Remove if unchecked
					$('.sidenav__search--refine ul').find(`[data-filter='${dataFilter}']`).fadeOut(200, function(){
					  _this.updateScrollHeight()
						_this.showResults()
					  $(this).remove()
					  _this.displayClearAll()
					if ($('.sidenav__search--refine ul li').length <= 0){
						_this.removeFilterListMargin()
						_this.displayAllResults()
					}
        })
      }
    })

		$('.form__radio input').on('click', function(){
			let currentRadio = $(this).closest('.form__radio')
			$(this).closest('ul').find('.form__radio').not(currentRadio).each(function(){
				$(this).find('input').prop('checked', false)
				let filter = $(this).closest('li').find('label p').html()
				let dataFilter = filter.replace(/\s+/g, '-').toLowerCase()
				$('.sidenav__search--refine ul').find(`[data-filter='${dataFilter}']`).remove()
			})
		})
  }

	// Unchecks all filters and removes all tags
    clearAll(){
        let _this = this
        $('.clear-all').click(function(){
            $('.sidenav__search--refine ul').find('li').remove()
            $('.sidenav__search .form__checkbox input:checkbox:checked').prop('checked', false)
						$('.sidenav__search .form__radio input:radio:checked').prop('checked', false)
            $('.sidenav__search--refine .clear-all').removeClass('is-active')
			$('.form__checkbox input, .form__radio input').closest('li').find('label p').removeClass('is-checked')
			_this.showResults()
			if ($('.sidenav__search--refine ul li').length <= 0){
				_this.removeFilterListMargin()
			}
			_this.displayAllResults()
        })
    }

	// Toggles the visibility of the clear all button
    displayClearAll(){
        this.removeFilterTag()
        if($('.sidenav__search--refine ul').find('li').length > 0){
            $('.sidenav__search--refine .clear-all').addClass('is-active')
        } else {
            $('.sidenav__search--refine .clear-all').removeClass('is-active')
        }
    }

	// Remove individual filter tag and uncheck corresponding checkbox
    removeFilterTag(){
        let _this = this
        $('.sidenav__search--refine .tag .icon--close').click(function(){
          var filter = $(this).prev('span').html()
          var dataFilter = $(this).closest('li').data('filter')
					// Find corresponding checkbox
					var checkbox =	$('.sidenav__search .form__checkbox input').filter(function(){
  					return $(this).closest('li').find('label p').html() === filter
   		    })
					var radio = $('.sidenav__search .form__radio input').filter(function(){
  					return $(this).closest('li').find('label p').html() === filter
   		    })
          checkbox.prop('checked', false)
					radio.prop('checked', false)
					checkbox.closest('li').find('label p').removeClass('is-checked')
					radio.closest('li').find('label p').removeClass('is-checked')
					// Remove filter and update list
          $('.sidenav__search--refine ul').find(`[data-filter='${dataFilter}']`).fadeOut(200, function(){
            $(this).remove()
            _this.updateScrollHeight()
            _this.displayClearAll()
						if ($('.sidenav__search--refine ul li').length <= 0){
							_this.removeFilterListMargin()
						}
        	})
					_this.defaultStateListener()
        })
    }

	addFilterListMargin(){
		$('.sidenav__search--refine ul').addClass('has-children')
	}

	removeFilterListMargin(){
		$('.sidenav__search--refine ul').removeClass('has-children')
	}

	showResults(){
		var resultsLength = $('.search-result').length
		$('.modal-search__apply button span').html("(" + resultsLength + ")")
	}

	appendViewMore(list){
		let listLength = list.next("ul").find("li").length
		let viewMore = listLength - 6
		list.next("ul").append("<div class='sidenav__search--view flex flex--center'><span>View more (" + viewMore + ")</span> <i class='ico ico--sm ico--f-chevron-d-s'></i></div>")
	}

    updateScrollHeight(){
        var scrollHeight = $('.sidenav__search--refine ul').prop('scrollHeight')
        $('.sidenav__search--refine ul').css("max-height", scrollHeight + "px")
    }
}
