import { store } from '../store/store.js';
import { debounce } from '../base/helpers.js';

/**
  * InitTable
  */
export default class InitTable {

	constructor() {

		/**
 		 * Initalise Class Cache
 		 * @type {obj}
 		 */
		this.cache = {
            $tableSticky:   $('.table--sticky'),
		};

		this.maxRowDisplay = 6;

		/**
 		 * Initalise Class Functions
 		 */
        this.renderStickyColumn();
        this.handleWindowResize();
		// this.handleScrollShadow();
		// this.expandViewMore();
	}

	/**
 	 * @property {Function} renderStickyColumn - Clones first column of table and renders sticky
 	 * @returns void
 	 */
    renderStickyColumn(){
        this.cache.$tableSticky.each(function(index, self){
            const $_self = $(self);

            $_self.find('tr').each(function(){
                const $firstRow = $(this).children().first();
                $firstRow.after($firstRow.clone().addClass(store.defaultClasses.sticky).attr("aria-hidden", true));
            });

            this.setColumnWidth($_self);
        }.bind(this));
    }

    /**
 	 * @property {Function} setColumnWidth - Sets inital widths of columns
 	 * @returns void
 	 */
    setColumnWidth($_self){
        $_self.find('th, td').each(function(){
            $(this).css("min-width", 'none').css("width", 'auto');
        });

        const width = $_self.find('th').first().width() + 100;

        $_self.find('th, td').each(function(){
            $(this).css("min-width", `${ width }px`).css("width", `${ width }px`);
        });
    }

    /**
 	 * @property {Function} handleWidthResize - Sets widths of columns on resize
 	 * @returns void
 	 */
    handleWidthResize(){
        this.cache.$tableSticky.each(function(index, self){
            if(store.cache.$window.width() < 768){
                this.setColumnWidth($(self));
            } else {
                $(self).css("min-width", 'none').css("width", 'auto');
            }
        }.bind(this));
    }


    /**
 	 * @property {Function} handleWindowResize - calls functions on resize
 	 * @returns void
 	 */
    handleWindowResize(){
        if (this.cache.$tableSticky.length === 0) return;

        store.cache.$window.on('resize', function(){
            debounce(this.handleWidthResize(), 100, true);
        }.bind(this));
    }

	handleScrollShadow(){
		$('.table--expand').scroll(function(){
			var scrollTop = $(this).scrollTop()
			var scrollBottom = $(this).prop('scrollHeight')
			var scroll = scrollTop + $(this).height()
			if(scrollTop == 0){
				$(this).removeClass('scroll-down scroll-up')
				$(this).addClass('scroll-up')
			} else if(scrollTop > 0 && scroll < scrollBottom){
				$(this).removeClass('scroll-down scroll-up')
				$(this).addClass('scrolling')
			} else if (scrollTop > 0 && scroll == scrollBottom) {
				$(this).removeClass('scrolling scroll-up')
				$(this).addClass('scroll-down')
			}
		})
	}

	expandViewMore(){
		let _this = this

		// Only works at the end of a table
		$('.table--expand').each(function(){
			$('.table--expandable').each(function(){
				// maxRowDisplay is 6
				if($(this).find('tr').length > _this.maxRowDisplay){
					// if length is greater than 6, move rows into a new table and hide
					$(this).closest('.table--expand').after("<div class='table--expansion__wrap'><table class='table--expansion table--structure'><tbody></tbody></table></div>")
					$(this).closest('.table--expand').next('.table--expansion__wrap').after("<button type='button' class='margin-btm-md-reset--36 full-width button button--quaternary button--sm text--semibold button--flex table--extend__button flex flex--center'></button>")
					$(this).find('tr:nth-of-type(n + 6)').appendTo($(this).closest('.table--expand').nextAll('.table--expansion__wrap').find('.table--expansion tbody'))

					// count number of rows in new table
					let tableExtendLength = $(this).closest('.table--expand').next('.table--expansion__wrap').find('.table--expansion tbody tr').length
					$(this).closest('.table--expand').nextAll('.table--extend__button').html("<span>View more (" + tableExtendLength + ")</span><i class='ico ico--md ico--f-caret-d'></i>")
					$(this).closest('.table--expand').next('.table--expansion__wrap').hide()

					// toggle table visibility
					$(this).closest('.table--expand').nextAll('.table--extend__button').on('click', function(event){
						event.stopPropagation()
						$(this).prev('.table--expansion__wrap').slideToggle()
						$(this).toggleClass('is-active')
						var text = $(this).find('span').text()
						$(this).find('span').html(text == "View more (" + tableExtendLength + ")" ? "View less" : "View more (" + tableExtendLength + ")");
					})
				}
			})
		})
	}
}
