import { store } from '../store/store.js'
import * as bodyScrollLock from 'body-scroll-lock'
import InitModal from './modal.js'

/**
 * InitHeader
 */
export default class InitHeader {
  constructor() {
    /**
     * Initalise Class Cache
     * @type {obj}
     */
    this.cache = {
      $header: $('.header').first(),
      $headerMain: $('.header').first().find('.header__main'),
      $headerNav: $('.nav'),
      $menuActivator: $('.header__burger'),
      $headerNavIcon: $('.nav__ico').not('.back'),
      $headerBack: $('.nav__back'),
      $headerSub: $('.nav__sub'),
      $headerSub1: $('.nav__sub--lev-1'),
      $headerSub2: $('.nav__sub--lev-2'),
      $headerSub3: $('.nav__sub--lev-3'),
      headerNavClass: '.nav',
      headerItem: '.nav > ul > li',
      headerMainClass: '.header__main',
      headerLogoClass: '.header__logo',
      state: $('.nav').find('.is-active:last').closest('.nav__sub').parents('.nav__sub').length,
      $activatedBody: document.querySelectorAll('.nav__sub'),
      cloned: false,
      $activeSlide: $('.nav').find('.is-active:last').closest('.nav__sub'),
    }

    /**
     * Initalise Class Functions
     */
    this.handleMenuActivator()
    this.listenOnScroll()
    this.handleSiteOverlayClick()
    this.handleHoverState()
    this.handlePreviewToggle()
    this.handleMobileNavigation()
    this.loadExtraMobileNagivation()
  }

  /**
   * @property {Function} handleMenuActivator  - Handles burger menu click functionality
   * @returns void
   */
  handleMenuActivator() {
    this.cache.$menuActivator.click(
      function (e) {
        const $_self = $(e.currentTarget)
        if ($_self.children().hasClass(store.defaultClasses.active)) {
          this.hideMenu()
        } else {
          store.cache.$body.addClass(store.defaultClasses.disabled)
          $_self.children().addClass(store.defaultClasses.active)
          $_self.parent().siblings(this.cache.headerNavClass).addClass(store.defaultClasses.active)
          this.cache.$header.addClass(store.defaultClasses.focus)
          $(this.cache.$headerNav.css('transition', 'background-color 10ms ease-in-out'))
          store.cache.$body.addClass('no-scroll')

          // $.each(this.cache.$activatedBody, function(index, element){
          //     bodyScrollLock.disableBodyScroll(element);
          // });
        }
      }.bind(this)
    )
  }

  hideMenu() {
    store.cache.$body.removeClass(store.defaultClasses.disabled)
    this.cache.$menuActivator.children().removeClass(store.defaultClasses.active)
    this.cache.$headerNav.removeClass(store.defaultClasses.active)
    this.cache.$header.removeClass(store.defaultClasses.focus)
    store.cache.$body.removeClass('no-scroll')

    // $.each(this.cache.$activatedBody, function(index, element){
    //     bodyScrollLock.enableBodyScroll(element);
    // });
  }

  /**
   * @property {Function} handleStickyHeader  - Handles sticky functionality of header
   * @returns void
   */
  handleStickyHeader(scroll) {
    if (this.cache.$header.length > 0) {
      if (scroll > 400 && store.cache.$window.width() >= store.breakPoints.large) {
        //reinit modal for search
        new InitModal('.header__main.is-sticky')
        if (!this.cache.cloned) {
          this.cache.cloned = true
          this.cache.$headerMain
            .clone()
            .addClass(store.defaultClasses.sticky)
            .insertAfter(this.cache.$headerMain)

          const $header = $(`${this.cache.headerMainClass}.${store.defaultClasses.sticky}`).find(
            this.cache.headerLogoClass
          )
          if ($header.hasClass('icon--logo-white')) {
            $header.removeClass('icon--logo-white').addClass('icon--logo-black')
          }

          this.cache.$header.addClass(store.defaultClasses.sticky)
        }
      } else {
        if (this.cache.cloned) {
          this.cache.cloned = false
          $(`${this.cache.headerMainClass}.${store.defaultClasses.sticky}`).remove()
          this.cache.$header.removeClass(store.defaultClasses.sticky)
        }
        this.cache.$header.removeClass(store.defaultClasses.focus)
      }

      if (scroll > 800 && store.cache.$window.width() >= store.breakPoints.large) {
        this.cache.$headerMain.attr('data-cloned', true)
        $(`${this.cache.headerMainClass}.${store.defaultClasses.sticky}`).addClass(
          store.defaultClasses.active
        )
      } else {
        this.cache.$headerMain.attr('data-cloned', false)
        $(`${this.cache.headerMainClass}.${store.defaultClasses.sticky}`).removeClass(
          store.defaultClasses.active
        )
      }
    }
  }

  /**
   * @property {Function} handleOnScroll  - call handleStickyHeader on scroll
   * @returns void
   */
  handleOnScroll() {
    const scroll = store.cache.$window.scrollTop()
    this.handleStickyHeader(scroll)
  }

  /**
   * @property {Function} listenOnScroll  - initiate listen on scroll
   * @returns void
   */
  listenOnScroll() {
    store.cache.$window.on('scroll', this.handleOnScroll.bind(this))
  }

  /**
   * @property {Function} loadExtraMobileNagivation  - Load mobile nav items greater than 3 deep
   * @returns void
   */
  loadExtraMobileNagivation() {
    if (typeof pageID === 'undefined') return

    let url = `/?a=884827&page=${pageID}&SQ_ASSET_CONTENTS_RAW`

    if (process.env.NODE_ENV === 'development') url = '/components/header/mobileNav.json'

    $.getJSON(
      url,
      function (data) {
        // console.log('state',this.cache.state)

        if (this.cache.state < 2) return

        $('.nav')
          .find('.is-active:last')
          .prepend(`<button class="nav__ico"><i class="ico ico--f-caret-r"></i></button>`)
          .after(this.createMobileNavHTML(data.menu_parent))
        this.createMobileNavHTML(data.menu_current, true)
        this.initState()
        this.setState()
      }.bind(this)
    )
  }

  createMobileNavHTML(menu, appendTo = false) {
    let html = `<div class="nav__sub"><ul>`
    let back = `<li class="nav__back nav__back--no-button"><a href="${menu.parent.asset_url}"><span>${menu.parent.asset_name}</span></a></li>`

    html += back

    menu.menu_items.forEach(
      function (listItem) {
        let submenu = ''
        let right_caret = ''
        if (listItem.children) {
          submenu = this.createMobileNavSubmenu(listItem.current, listItem.children)
          right_caret = `<button class="nav__ico"><i class="ico ico--f-caret-r"></i></button>`
          if (appendTo) {
            $('.nav')
              .find(`[data-id=${listItem.current.asset_assetid}]`)
              .prepend(`<button class="nav__ico"><i class="ico ico--f-caret-r"></i></button>`)
              .after(submenu)
            // console.log(`[data-id=${listItem.current.asset_assetid}]`)
          }
        }

        html += `<li><a href="${listItem.current.asset_url}" data-id="${listItem.current.asset_assetid}">${right_caret}<span>${listItem.current.asset_name}</span></a>${submenu}</li>`
      }.bind(this)
    )

    html += `</ul></div>`
    return html
  }

  createMobileNavSubmenu(parent, submenu) {
    let html = `<div class="nav__sub"><ul>`
    let back = `<li class="nav__back"><a href="#"><span>${parent.asset_name}</span><button class="nav__ico back"><i class="ico ico--f-caret-l"></i></button></a></li>`

    html += back

    submenu.forEach(function (listItem) {
      let current = ''

      if (pageID === parseInt(listItem.asset_assetid)) current = `class="is-active"`

      html += `<li><a href="${listItem.asset_url}" data-id="${listItem.asset_assetid}" ${current}><span>${listItem.asset_name}</span></a></li>`
    })

    html += `</ul></div>`

    return html
  }

  /**
   * @property {Function} handleMobileNavigation  - toggle accordians
   * @returns void
   */
  handleMobileNavigation() {
    this.cache.$headerBack.find('.nav__ico').addClass('back')

    const handleOverFlow = function (value) {
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        return {
          '-ms-overflow-x': value.x,
          '-ms-overflow-y': value.y,
        }
      } else {
        return {
          '-webkit-overflow-x': value.x,
          '-webkit-overflow-y': value.y,
          '-moz-overflow-x': value.x,
          '-moz-overflow-y': value.y,
          '-o-overflow-x': value.x,
          '-o-overflow-y': value.y,
          'overflow-x': value.x,
          'overflow-y': value.y,
        }
      }
    }

    const handleTransitionEnd = function (changeOverflow, initialise) {
      if (initialise) {
        changeOverflow()
      } else {
        this.cache.$headerSub1.one(
          'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
          changeOverflow
        )
      }
    }.bind(this)

    // Transition between states
    if (store.cache.$window.width() <= store.breakPoints.large) {
      this.initState()
      this.setState()
    }

    let _this = this
    $('.nav').on('click', '.nav__ico:not(.back)', function (e) {
      $(this).closest('ul').closest('ul').find('li > .nav__sub').removeClass('is-focus')
      $(this).closest('li').find('> .nav__sub').addClass('is-focus')
      // $(this).closest('li').find('> .nav__sub').closest()
      _this.state++
      _this.setState()
      e.preventDefault()
    })

    $('.nav').on('click', '.nav__back:not(.nav__back--no-button)', function (e) {
      _this.state--
      _this.setState()
      e.preventDefault()
    })
  }

  setState() {
    this.cache.$headerSub1.css(this.handleTransform(`-${this.state * 100}%`))
  }

  // Set states between levels

  initState() {
    $('.nav').find('.is-active:last').parents('.nav__sub').addClass('is-focus')
    //		this.cache.$activeSlide.addClass(store.defaultClasses.focus);
    //		this.cache.$activeSlide.parents('.nav__sub').addClass(store.defaultClasses.focus);
    this.state = $('.nav').find('.is-active:last').closest('.nav__sub').parents('.nav__sub').length
  }

  handleTransform(value) {
    return {
      '-webkit-transform': 'translateX(' + value + ')',
      '-moz-transform': 'translateX(' + value + ')',
      '-ms-transform': 'translateX(' + value + ')',
      '-o-transform': 'translateX(' + value + ')',
      transform: 'translateX(' + value + ')',
    }
  }

  /**
   * @property {Function} handleSiteOverlayClick  - remove active states on overlay click
   * @returns void
   */
  handleSiteOverlayClick() {
    this.cache.$header.click(
      'click',
      function (e) {
        if (!$(e.target).is('.header__burger, .header__burger *, .nav, .nav *')) {
          this.hideMenu()
        }
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleDocumentResize  - Handles document resize functionality hiding mobile menu
   * @returns void
   */
  // handleDocumentResize(){
  // $(this.cache.$headerNav.css('top',this.cache.$headerMain.outerHeight()))
  //
  //     store.cache.$window.on('resize', function() {
  //         clearTimeout(window.resizedFinished);
  //         window.resizedFinished = setTimeout(function(){
  //             if(store.cache.$window.width() >= store.breakPoints.large){
  //                 this.hideMenu();
  //                 this.cache.$headerSub.removeAttr("style");
  //             } else {
  // 			$(this.cache.$headerNav.css('top',this.cache.$headerMain.outerHeight()))
  // 			this.setState()
  // 			this.initState()
  //             }
  //         }.bind(this), 250);
  //     }.bind(this));
  // }

  /**
   * @property {Function} handleHoverState
   * @returns void
   */
  handleHoverState() {
    store.cache.$document.on(
      'mouseenter',
      this.cache.headerItem,
      function () {
        if (store.cache.$window.width() >= store.breakPoints.large) {
          this.cache.$header.addClass(store.defaultClasses.focus)

          if (this.cache.$header.hasClass(store.defaultClasses.sticky)) {
            store.cache.$body.addClass(store.defaultClasses.disabled)
          }
        }
      }.bind(this)
    )

    store.cache.$document.on(
      'mouseleave',
      this.cache.headerItem,
      function () {
        if (store.cache.$window.width() >= store.breakPoints.large) {
          this.cache.$header.removeClass(store.defaultClasses.focus)
          store.cache.$body.removeClass(store.defaultClasses.disabled)
        }
      }.bind(this)
    )
  }

  handlePreviewToggle() {
    $('#header-toggle').on(
      'change',
      function () {
        if (this.cache.$header.hasClass('header--transparent')) {
          this.cache.$header.removeClass('header--transparent')
          $('.header__logo').removeClass('icon--logo-white').addClass('icon--logo-black')
        } else {
          this.cache.$header.addClass('header--transparent')
          $('.header__logo').removeClass('icon--logo-black').addClass('icon--logo-white')
        }
      }.bind(this)
    )
  }
}
