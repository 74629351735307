export default class InitBanner {

	constructor(){
		this.cache = {
			$bannerInfo:	$('.banner--info'),
		};
		if(this.cache.$bannerInfo.length){
			this.setMargin();
		}
	}

	setMargin(){
        var margin = $('.banner--info__tile').height() / 2
        $('.banner--info h1').css('margin-bottom', margin + 'px')
        $('.banner--info__tile').css('margin-top', -margin + 'px')
	}
}
