export default class InitDropdown {
  constructor() {
    this.cache = {
      $dropdown: $('.dropdown'),
      $dropdownButton: $('.dropdown button').not('.clear button'),
      $dropdownList: $('.dropdown').find('ul'),
      $dropdownItem: $('.dropdown').find('ul li'),
      $dropdownCheckbox: $('.dropdown').find('ul li input[type=checkbox]'),
      $clearDropdown: $('.dropdown').find('.clear button'),
    }
    if (this.cache.$dropdown.length) {
      this.setInputValues()
      this.setListeners()
    }
  }

  setInputValues() {
    this.cache.$dropdownItem.each(function () {
      let value = ''
      if ($(this).find('input[type=checkbox]').length) {
        value = $(this).find('label p').text()
        if (!value) {
          value = $(this).find('label').text()
        }
        $(this).find('input[type=checkbox]').val(value)
      } else {
        value = $(this).text()
        $(this).attr('data-value', value)
      }
    })
  }

  setListeners() {
    let _this = this
    // const defaultButtonText = this.cache.$dropdownButton.find('span').text()

    $(document).on('click', function (e) {
      if (!$(e.target).is('.dropdown *')) {
        $('.dropdown').removeClass('is-active')
        $('.dropdown').find('ul').removeClass('is-active')
      }
    })
    $('.dropdown').each(function () {
      let defaultButtonText = $(this).find('button span').text()
      $(this).find('button span').attr('data-default', defaultButtonText)

      if (!defaultButtonText.length) {
        defaultButtonText = $(this).find('div[role=button] span').text()
        $(this).find('div[role=button] span').attr('data-default', defaultButtonText)
      }
    })
    $('.dropdown button, .dropdown div[role=button]')
      .not('.clear button')
      .on('click', function () {
        let selectedDropdown = $(this).closest('.dropdown')
        _this.toggleUnfocusedLists(selectedDropdown)
      })
    this.cache.$dropdownItem.on('click', function () {
      _this.handleInputChange($(this))
    })
  }

  toggleUnfocusedLists(selectedDropdown) {
    this.cache.$dropdown.not(selectedDropdown).removeClass('is-active')
    this.cache.$dropdownList.not(selectedDropdown.find('ul')).removeClass('is-active')
    selectedDropdown.toggleClass('is-active')
    selectedDropdown.find('ul').toggleClass('is-active')
  }

  toggleCurrentList(dropdown) {
    if (dropdown.find('ul').hasClass('is-active')) {
      dropdown.removeClass('is-active')
      dropdown.find('ul').removeClass('is-active')
    } else {
      dropdown.addClass('is-active')
      dropdown.find('ul').addClass('is-active')
    }
  }

  handleInputChange($self) {
    var input = $self.closest('.dropdown').find('button span')
    if (!input.length) {
      input = $self.closest('.dropdown').find('div[role=button] span')
    }

    $self.closest('.dropdown').find('button').addClass('is-active')
    $self.closest('.dropdown').find('div[role=button]').addClass('is-active')

    if (!$self.hasClass('is-active')) {
      $self.addClass('is-active')
      $self.find('input[type=checkbox]').prop('checked', true)
    } else {
      $self.removeClass('is-active')
      $self.find('input[type=checkbox]').prop('checked', false)
    }
    if (!$self.find('input[type=checkbox]').length) {
      $self.closest('.dropdown').find('ul li').not($self).removeClass('is-active')
      $self.closest('ul').removeClass('is-active')
      $self.closest('.dropdown').removeClass('is-active')
    }
    if ($self.find('input[type=checkbox]').length) {
      if ($self.closest('.dropdown').find('ul li input[type=checkbox]:checked').length === 0) {
        input.text($self.closest('.dropdown').find('button span').data('default'))
      } else if (
        $self.closest('.dropdown').find('ul li input[type=checkbox]:checked').length === 1
      ) {
        let value = $self.closest('.dropdown').find('ul li input[type=checkbox]:checked').val()
        input.text(value)
      } else {
        let value =
          $self.closest('.dropdown').find('ul li input[type=checkbox]:checked').length +
          ' selections'
        input.text(value)
      }
    } else {
      let value = $self.data('value')
      input.text(value)
    }
  }
}
