import { store } from '../store/store.js';
/**
 * InitAccordion
 */
export default class InitAccordion{

	constructor(){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
            $accordion:             $('.accordion'),
			$accordionHeading: 		$('.accordion__heading'),
            accordionHeadingClass:  '.accordion__heading',
			accordionBodyClass:		'.accordion__body',
			iconPlusMinusClass:		'.icon--plusminus'
		};

		/**
		 * Initalise Class Functions
		 */
        // this.handleAccordionLoad();
		if(this.cache.$accordion.length){
			this.handleAccordionClick();
			this.handleAccordionKeyboardUser();
		}
	}

    /**
	 * @property {Function} handleAccordianLoad  - makes first accordian active
	 * @returns void
	 */
    handleAccordionLoad(){
        this.cache.$accordion.each(function(index, self){
            const $_self = $(self);
            $_self.find(this.cache.accordionHeadingClass).first().addClass(store.defaultClasses.active).attr('aria-expanded', true);
            $_self.find(this.cache.iconPlusMinusClass).first().addClass(store.defaultClasses.active);
			$_self.find(this.cache.accordionBodyClass).first().slideDown();
			$_self.find(this.cache.accordionBodyClass).children().attr('tabindex',0);
        }.bind(this));
    }

    /**
	 * @property {Function} handleAccordianToggle  - Handles accordion slide effect - open/close
	 * @returns void
	 */
	handleAccordianToggle($_this){
		const $accordionBody = $_this.siblings(this.cache.accordionBodyClass);
		$_this.toggleClass(store.defaultClasses.active);
		$_this.find(this.cache.iconPlusMinusClass).toggleClass(store.defaultClasses.active);
		$_this.attr('aria-expanded') == 'false' ? $_this.attr('aria-expanded', true) : $_this.attr('aria-expanded', false);
		$accordionBody.slideToggle();
	}

	/**
	 * @property {Function} handleAccordionClick  - Handles accordion click - open/close
	 * @returns void
	 */
	handleAccordionClick(){
		this.cache.$accordionHeading.click(function(e){
			this.handleAccordianToggle($(e.currentTarget));
		}.bind(this));
	}

	/**
	 * @property {Function} handleAccordionKeyboardUser  - Handles accordion spacebar keyboard stroke - open/close
	 * @returns void
	 */
	handleAccordionKeyboardUser(){
		this.cache.$accordionHeading.keydown(function(e){
			if (e.keyCode == 32) {
				e.preventDefault();
				this.handleAccordianToggle($(e.target));
			}
		}.bind(this));
	}
}
