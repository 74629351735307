export default class InitSearchResults {

	constructor() {
		this.clearSearchInput()
        this.searchListener()
		this.handleResize()
		this.onLoad()
	}

	onLoad(){
		$('.search-result__clear').hide()
	}

    clearSearchInput(){
		let _this = this
        $('.banner--search input').keyup(function(){
			_this.searchListener()
            if($('.banner--search input').val()){
                $('.search-result__clear').fadeIn("fast")
            } else {
                $('.search-result__clear').fadeOut("fast")
            }
        })

        $('.search-result__clear').click(function(){
            if($('.banner--search input').val()){
                $('.banner--search input').val("")
				$('.search-result__clear').fadeOut("fast")
				_this.searchListener()
            }
        })
    }

    // Changes wording of results depending on screen size
    searchListener(){
		if ($(window).width() >= 768){
			if(!$('.banner--search input').val()){
				$('#search-phrase').removeClass('is-active')
			} else {
				this.displaySearchWord()
			}
		} else {
			$('#search-phrase').css('display','none')
		}
    }

	handleResize(){
		let _this = this
		$(window).resize(function(){
			if ($(window).width() < 768){
				$('#search-phrase').css('display','none')
			} else {
				_this.displaySearchWord()
			}
		})
	}

	displaySearchWord(){
		$('#search-phrase').addClass('is-active')
		var searchWord = $('.banner--search input').val()
		$('#search-word').html('"' + searchWord + '"')
	}
}
