import { store } from '../store/store.js';

/**
 * InitTooltip
 */
export default class InitVideo{

	constructor(){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$video: 	$('.video')
		}

		if(this.cache.$video.length){
			this.renderVideoSize();
			this.setVideoDimentions();
			this.handleWindowResize();
		}
	}

	/**
	 * @property {Function} renderVideoSize  - sets aspect ratio as data attribute and remove width and height attributes
	 * @returns void
	 */
	renderVideoSize(){
		this.cache.$video.each(function(index, self){
            const $_self = $(self);
            const $iframe = $_self.find('iframe');
            $iframe.data('aspectRatio', $iframe.height() / $iframe.width()).removeAttr('height').removeAttr('width');
        });
	}

    /**
	 * @property {Function} setVideoDimentions  - sets new width and height based on aspect ratio
	 * @returns void
	 */
    setVideoDimentions(){
        this.cache.$video.each(function(index, self) {
            const $_self = $(self);
            const $iframe = $_self.find('iframe');
            const newWidth = $_self.width();
            $iframe.width(newWidth).height(newWidth * $iframe.data('aspectRatio'));
        });
    }

    /**
	 * @property {Function} handleWindowResize  - calls setVideoDimentions on window resize
	 * @returns void
	 */
    handleWindowResize(){
        store.cache.$window.on('resize', function(){
            this.setVideoDimentions();
        }.bind(this));
    }
}
