export default class InitInfographicCircle{

	constructor(){
    if($('.infographic__circle').length){
      this.handleListeners()
    }
	}

  handleListeners(){
		// Resize circle depending on screen size
    $(window).on('load resize', function() {
      if($(window).width() >= 768){
        var radius = $('.infographic__circle').data('mdwidth')/2
      } else {
        radius = $('.infographic__circle').data('smwidth')/2
      }
      this.calculateCircle(radius)
    }.bind(this))
  }

  calculateCircle(radius){
    var type = 1, // Circle type - 1 whole, 0.5 half, 0.25 quarter
    start = -90, // Shift start from 0
    $elements = $('.infographic__circle').find('.infographic__icon'),
    numberOfElements = (type === 1) ?  $elements.length : $elements.length - 1, // For even distribution of elements when not full circle
    slice = 360 * type / numberOfElements;

    $elements.each(function(i) {
      var rotate = slice * i + start,
          rotateReverse = rotate * -1,
          tag = $(this).data('tag');
      $(this).css({
        'transform': 'rotate(' + rotate + 'deg) translate('+radius+'px) rotate(' + rotateReverse + 'deg)'
      });

			// Add tags
      if($(this).find('span').length <= 0){
        $(this).append('<span>'+tag+'</span>')
        if(rotate > 0 && rotate < 180){
          $(this).find('span').css('bottom', '-40px')
        } else if(rotate === 0) {
					$(this).find('span').css('left', '110px')
				} else if(rotate === 180){
					$(this).find('span').css('right', '110px')
        } else {
					$(this).find('span').css('top', '-40px')
				}
      }
    });
  }
}
