import { store } from '../store/store.js';
import * as bodyScrollLock from 'body-scroll-lock';
import InitPopup from './popup.js';

/**
 * InitModal
 */
export default class InitModal{

	constructor(parentSelector=''){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$modalActivator: 	    $(`${parentSelector} .js-modal-activator`),
            $modalDeactivator:      $(`${parentSelector} .js-modal-deactivator`),
			$modal:				    $(`${parentSelector} .js-modal`),
			modalClass:			    '.modal',
			modalCloseClass:	    '.modal__prev-click',
			containerClass:		    '.modal__container, .modal__container *',
			parentSelector:			parentSelector,
			$modalStep:				$('.js-modal--step'),
			modalBtnNextClass:		'.modal__next-click',
			modalBtnPrevClass:		'.modal__prev-click',
			modalFooterClass:		'.modal__footer',
		};

		/**
		 * Initalise Class Functions
		 */
		this.handleActivation();
		this.handleDeactivation();
		this.handleClickOutsideContainer();
		this.handleKeypress();

		this.initStepsCounter();
	}



	/**
	 * @property {Function} handleModalActivation - adds active class to target modal
	 * @returns void
	 */
	handleActivation(){
		this.cache.$modalActivator.off().on("click", function(e){
			this.cache.activeModal = $(e.currentTarget).data('target');
            const $modal = $(this.cache.activeModal);
			$modal.addClass(store.defaultClasses.active);
            $modal.attr('aria-hidden', false);
			store.cache.$html.addClass("no-scroll");
			store.cache.$body.addClass("no-scroll");
            store.cache.$body.addClass(store.defaultClasses.disabled);
            bodyScrollLock.disableBodyScroll(document.querySelector('.modal'));
			$('#form-iframe', parent.document).height($("body").height()+5);
		}.bind(this));
	}

	/**
	 * @property {Function} handleModalCloseClick - calls hideModal on close icon click
	 * @returns void
	 */
	handleDeactivation(){
		this.cache.$modalDeactivator.click(function(){
			this.hideModal();
		}.bind(this));
	}

	/**
	 * @property {Function} handleClickOutsideContainer - calls hideModal when clicking outside modal
	 * @returns void
	 */
	handleClickOutsideContainer(){
		this.cache.$modal.click(function(e) {
            if($(e.target).closest(this.cache.modalClass).length > 0 && !$(e.target).is(this.cache.containerClass)){
                this.hideModal();
            }
		}.bind(this));
	}

	/**
	 * @property {Function} handleKeypress - calls hideModal on esc key press
	 * @returns void
	 */
	handleKeypress(){
		store.cache.$window.keydown(function(e) {
			if (this.cache.$modal.hasClass(store.defaultClasses.active) && e.keyCode === 27) {
				this.hideModal();
			}
		}.bind(this));
	}

	open(delay=0){
		setTimeout(function() {
			$(this.cache.parentSelector).show();
			this.cache.$modal.addClass(store.defaultClasses.active);
			this.cache.$modal.attr('aria-hidden', false);
			store.cache.$body.addClass(store.defaultClasses.disabled);
			bodyScrollLock.disableBodyScroll(document.querySelector('.modal'));
		}.bind(this), delay);

	}

		/**
	 * @property {Function} initStepsCounter - add and initialise modal steps counter
	 * @returns void
	 */
	initStepsCounter() {
		this.cache.$modalStep.each(function(index, self){
			const $_self = $(self);
			const stepCount = {
				min: 1,
				max: $_self.find('.modal__step').length
			};

			$_self.find('.modal__step').first().addClass(store.defaultClasses.active);
			$_self.data("current", 1);

			$_self.find(this.cache.modalFooterClass).before(
				`<div class="modal__body modal__step__counter">
					<p><span class="modal__step__counter--current">${ $_self.data("current") }</span> of <span class="modal__step__counter--total">${ stepCount.max }</span></p>
				</div>`
			);

			this.handleStepIncrease($_self, stepCount);
			this.handleStepDecrease($_self, stepCount);

		}.bind(this));
	}

	/**
	 * @property {Function} handleStepIncrease - move to next step and increase steps counter
	 * @returns void
	 */
	handleStepIncrease($_self, stepCount){
		$_self.find(this.cache.modalBtnNextClass).off().on('click', function(){
			let currentStep = $_self.data("current");
			if(currentStep === stepCount.max){
				this.hideModal();
			} else if(currentStep < stepCount.max){
				currentStep++;
				$_self.data("current", currentStep);
				$_self.find('.modal__step__counter--current').text(currentStep);
				$_self.find('.modal__step.is-active').removeClass(store.defaultClasses.active).next('.modal__step').addClass(store.defaultClasses.active);
			}
			this.handleStepsCounter($_self, $_self.data("current"), stepCount);
		}.bind(this));
	}

	/**
	 * @property {Function} handleStepDecrease - move to previous step and decrease steps counter
	 * @returns void
	 */
	handleStepDecrease($_self, stepCount){
		$_self.find(this.cache.modalBtnPrevClass).off().on('click', function(){
			if($_self.data("current") === stepCount.min){
				this.hideModal();
			}else if($_self.data("current") > stepCount.min){
				$_self.data("current", $_self.data("current") - 1);
				$_self.find('.modal__step__counter--current').text($_self.data("current"));
				$_self.find('.modal__step.is-active').removeClass(store.defaultClasses.active).prev('.modal__step').addClass(store.defaultClasses.active);
			}
			this.handleStepsCounter($_self, $_self.data("current"), stepCount);
		}.bind(this));
	}

	/**
	 * @property {Function} handleStepsCounter - update modal buttons text based on step
	 * @returns void
	 */
	handleStepsCounter($_self, modalCurrentStep, stepCount) {
		if (modalCurrentStep === stepCount.max) {
			$_self.find('.modal__next-click').text('Okay');
		} else {
			$_self.find('.modal__next-click').text('Next');
		}

		if (modalCurrentStep === stepCount.min) {
			$_self.find('.modal__prev-click').text('Cancel');
		} else {
			$_self.find('.modal__prev-click').text('Previous');
		}
	}

	/**
	 * @property {Function} hideModal - remove active class from modal
	 * @returns void
	 */
	hideModal(){
		//TODO put this in a better place
		//Adds tongue for popup form

		if(this.cache.parentSelector === '.marketing-modal') {
			//destroy iframe
			if($('iframe.form-submitted').length === 0){
				$('#form-iframe').remove()
				new InitPopup(true)
			}
		}
		store.cache.$html.removeClass("no-scroll");
		store.cache.$body.removeClass("no-scroll");
		this.cache.$modal.removeClass(store.defaultClasses.active);
        this.cache.$modal.attr('aria-hidden', true);
        store.cache.$body.removeClass(store.defaultClasses.disabled);
		bodyScrollLock.enableBodyScroll(document.querySelector('.modal'));

		this.cache.$modal.each(function(){
			const $this = $(this);
			$this.data("current", 1);
			$this.find('.modal__step').removeClass('is-active').first().addClass('is-active');
			$this.find('.modal__step__counter--current').text($this.data("current"));
		});
	}
}
