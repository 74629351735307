import { store } from '../store/store.js';

/**
 * InitToggle
 */
export default class InitToggle {

	constructor() {

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$toggle: 				$('.toggle'),
			$toggleInput:			$('.toggle__input'),
			$toggleWrap:			$('.toggle__wrap'),
            $toggleSwitch:          $('.toggle__switch'),
			toggleClass:			'.toggle',
			toggleInputClass:		'.toggle__input',
			toggleOptionsClass: 	'.toggle__option'
		};

		/**
		 * Initalise Class Functions
		 */
        this.handleToggleSlide();
		this.handleToggleFocus();
		this.handleToggleBlur();

		store.cache.$window.on('load', function(){
			this.setToggleSize();
			this.setInitialTransition();
		}.bind(this));

	}

	/**
	 * @property {Function} setToggleSize  - Handles tag closing functionality
	 * @returns void
	 */
	setToggleSize() {
		this.cache.$toggleInput.each(function(index, self){
			const $_self = $(self);
			const $toggleOptions = $_self.find(this.cache.toggleOptionsClass);
			let toggleWidth = 0;

			$toggleOptions.each(function(){
				if($(this).innerWidth() >= toggleWidth){
					toggleWidth = $(this).innerWidth();
				}
			});
			$_self.width(toggleWidth + 4);
			this.cache.$toggleWrap.width(toggleWidth * 2);
			$toggleOptions.width(toggleWidth);
		}.bind(this));
	}

	/**
	 * @property {Function} setInitialTransition  - sets transition values after page load. Avoids transition flash.
	 * @returns void
	 */
	setInitialTransition(){
		this.cache.$toggleWrap.css({
			WebkitTransition : 'transform 0.2s ease-in-out',
			MozTransition    : 'transform 0.2s ease-in-out',
			MsTransition     : 'transform 0.2s ease-in-out',
			OTransition      : 'transform 0.2s ease-in-out',
			transition       : 'transform 0.2s ease-in-out'
		});
	}

	/**
	 * @property {Function} handleToggleChange - handles mousemove monitoring to toggle class and value
	 * @returns void
	 */
    handleToggleChange($this){
        let mousePosition = 0;

        $this.bind('touchmove', function(e){
            e.preventDefault();
            const $parent = $this.closest(this.cache.toggleClass);
            const $input = $parent.find('input');
            $this.removeClass(store.defaultClasses.focus);

            const pageX = e.type === "touchmove" ? e.originalEvent.touches[0].pageX : e.pageX;

            if (mousePosition === 0) mousePosition = pageX;
			if($parent.hasClass(store.defaultClasses.active)){
                if (mousePosition > pageX) {
                    $input.click();
                    $parent.removeClass(store.defaultClasses.active);
                }
			} else {
                if (mousePosition < pageX) {
                    $input.click();
                    $parent.addClass(store.defaultClasses.active);
                }
			}
            mousePosition = pageX;
        }.bind(this));
    }

    /**
	 * @property {Function} handleToggleSlide - binds mouse functionality
	 * @returns void
	 */
    handleToggleSlide(){
        this.cache.$toggleSwitch.on('touchstart mousedown', function(e){
            const $_self = $(e.target);
            e.preventDefault();
            $_self.addClass(store.defaultClasses.focus);
            this.handleToggleChange($_self);
        }.bind(this));

        this.cache.$toggleSwitch.on('touchend mouseup', function(e){
            const $_self = $(e.target);
            e.preventDefault();
            if($_self.hasClass(store.defaultClasses.focus)){
                const $parent = $_self.closest(this.cache.toggleClass);
                const $input = $parent.find('input');

                $parent.toggleClass(store.defaultClasses.active);
                $input.prop("checked", !$input.prop("checked"));
                $_self.removeClass(store.defaultClasses.focus);
            }
        }.bind(this));

        store.cache.$document.on('touchend mouseup', function(){
            this.cache.$toggleSwitch.unbind('mousemove touchmove');
        }.bind(this));
    }

	/**
	 * @property {Function} handleToggleFocus - add class to parent on focus
	 * @returns void
	 */
	handleToggleFocus(){
		this.cache.$toggle.find('input').focus(function(e){
			$(e.currentTarget).closest(this.cache.toggleInputClass).addClass(store.defaultClasses.focus);
		}.bind(this));
	}

	/**
	 * @property {Function} handleToggleBlur - removes class from parent on blur
	 * @returns void
	 */
	handleToggleBlur(){
		this.cache.$toggle.find('input').blur(function(e){
			$(e.currentTarget).closest(this.cache.toggleInputClass).removeClass(store.defaultClasses.focus);
		}.bind(this));
	}
}
