import Tooltip from 'tooltip.js';
import { store } from '../store/store.js';

/**
 * InitTooltip
 */
export default class InitTooltip{

	constructor(){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$tooltip: 	$('.tooltip')
		}

		if(this.cache.$tooltip.length){
			this.createTooltips();
		}
	}

	/**
	 * @property {Function} renderToolTips  - renders tooltip to DOM and handles click/hover events
	 * @returns void
	 * uses tooltip.js
	 */
	createTooltips(){
		const trigger = store.cache.$html.hasClass('no-touchevents') ? "hover focus" : "click";

		$.each(this.cache.$tooltip, function(){
			new Tooltip($(this), {
				trigger: trigger,
				placement: $(this).data('tooltip-position'),
				template: '<div class="tooltip__body"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
				title: $(this).data('tooltip-content'),
				innerSelector: '.tooltip__inner',
				arrowSelector: '.tooltip__arrow',
				closeOnClickOutside: true,
				delay: 100,
			});
		});
	}
}
