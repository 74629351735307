import { store } from '../store/store.js';

/**
 * InitTag
 */
export default class InitTag{

	constructor(){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$tag:				$('.tag'),
			$tagCloseButton: 	$('.tag button')
		};

		/**
		 * Initalise Class Functions
		 */
		if(this.cache.$tag.length){
			this.handleTagClose();
			this.handleTagFocus();
			this.handleTagBlur();
		}
	}


	/**
	 * @property {Function} handleTagClose  - Handles tag closing functionality
	 * @returns void
	 */
	handleTagClose(){
		this.cache.$tagCloseButton.click(function(){
			$(this).parent().fadeOut();
		});
	}

	/**
	 * @property {Function} handleTagClose  - Adds focus class on focus
	 * @returns void
	 */
	handleTagFocus(){
		this.cache.$tagCloseButton.focus(function(){
			$(this).parent().addClass(store.defaultClasses.focus);
		});
	}

	/**
	 * @property {Function} handleTagClose  - removes focus class on blur
	 * @returns void
	 */
	handleTagBlur(){
		this.cache.$tagCloseButton.blur(function(){
			$(this).parent().removeClass(store.defaultClasses.focus);
		});
	}
}
