import { store } from '../store/store.js'
/**
 * Inittab
 */
export default class InitTab {
  constructor() {
    /**
     * Initalise Class Cache
     * @type {obj}
     */
    this.cache = {
      $tab: $('.tab'),
      $tabHeading: $('.tab__heading'),
      tabClass: '.tab',
      tabHeadingClass: '.tab__heading',
      tabBodyClass: '.tab__body',
      iconPlusMinusClass: '.icon--plusminus',
    }

    /**
     * Initalise Class Functions
     */
    if (this.cache.$tab.length) {
      this.handleTabLoad()
      this.handleTabClick()
      this.handleKeyboardUser()
    }
  }

  /**
   * @property {Function} handleTabLoad  - makes first tab active
   * @returns void
   */
  handleTabLoad() {
    this.cache.$tab.each(
      function (index, self) {
        const $_self = $(self)
        $_self
          .find(this.cache.tabHeadingClass)
          .first()
          .addClass(store.defaultClasses.active)
          .attr('aria-expanded', true)
        $_self.find(this.cache.iconPlusMinusClass).first().addClass(store.defaultClasses.active)

        if (store.cache.$window.width() < 768) {
          $_self
            .find(this.cache.tabBodyClass)
            .first()
            .slideDown()
            .addClass(store.defaultClasses.active)
        } else {
          $_self.find(this.cache.tabBodyClass).first().addClass(store.defaultClasses.active)
        }
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleTabSwitch  - Handles accordion switch effect - open/close
   * @returns void
   */
  handleTabSwitch($_this) {
    if ($_this.hasClass(store.defaultClasses.active)) return

    const $tabBody = $_this.siblings(this.cache.tabBodyClass)

    $_this
      .closest(this.cache.tabClass)
      .find(this.cache.tabHeadingClass)
      .removeClass(store.defaultClasses.active)
      .attr('aria-expanded', false)
    $_this
      .closest(this.cache.tabClass)
      .find(this.cache.iconPlusMinusClass)
      .removeClass(store.defaultClasses.active)
      .attr('tabindex', -1)

    if (store.cache.$window.width() < 768) {
      $_this
        .closest(this.cache.tabClass)
        .find(this.cache.tabBodyClass)
        .slideUp()
        .removeClass(store.defaultClasses.active)
      $tabBody.slideDown()
      setTimeout(function () {
        $tabBody.addClass(store.defaultClasses.active)
      }, 100)
    } else {
      $_this
        .closest(this.cache.tabClass)
        .find(this.cache.tabBodyClass)
        .removeAttr('style')
        .removeClass(store.defaultClasses.active)
      $tabBody.addClass(store.defaultClasses.active).css('display', 'block')
    }

    $_this.addClass(store.defaultClasses.active)
    $_this.find(this.cache.iconPlusMinusClass).addClass(store.defaultClasses.active)
    $_this.attr('aria-expanded', true)
  }

  /**
   * @property {Function} handleTabClick  - Handles tab click - open/close
   * @returns void
   */
  handleTabClick() {
    this.cache.$tabHeading.click(
      function (e) {
        this.handleTabSwitch($(e.currentTarget))
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleKeyboardUser  - Handles tab spacebar keyboard stroke - open/close
   * @returns void
   */
  handleKeyboardUser() {
    this.cache.$tabHeading.keydown(
      function (e) {
        if (e.keyCode == 32) {
          e.preventDefault()
          this.handleTabSwitch($(e.target))
        }
      }.bind(this)
    )
  }
}
