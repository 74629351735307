import { store } from '../store/store.js'

/**
 * InitAlert
 */
export default class InitAlert {
  constructor() {
    /**
     * Initalise Class Cache
     * @type {obj}
     */
    this.cache = {
      $alert: $('.alert'),
      alertClass: '.alert',
      alertDeactivator: '.alert__deactivator',
    }

    /**
     * Initalise Class Functions
     */
    if (this.cache.$alert.length) {
      this.handleAlertClose()
    }

    if ($('.alert--fixed').length) {
      this.handleAlertFixedClose()
      this.handlePadding()
    }

    if (typeof assetid !== 'undefined' && typeof pageAlerts !== 'undefined') this.popup()
  }

  /**
   * @property {Function} handleAlertClose - handles closing of the alert popup
   * @returns void
   */
  handleAlertClose() {
    store.cache.$document.on(
      'click',
      this.cache.alertDeactivator,
      function (e) {
        $(e.currentTarget)
          .closest(this.cache.alertClass)
          .fadeOut('normal', function () {
            $(this).remove()
          })
        $(e.currentTarget)
          .closest(this.cache.alertClass)
          .addClass('is-inactive')
          .one('transitionend', function () {
            $(this).remove()
          })
      }.bind(this)
    )
  }

  handleAlertFixedClose() {
    console.log('hello')
    store.cache.$document.on(
      'click',
      this.cache.alertDeactivator,
      function (e) {
        $('.header').css({ paddingTop: 0 })
        $(e.currentTarget)
          .closest('.alert--fixed')
          .addClass('is-inactive')
          .one('transitionend', function () {
            $(this).remove()
          })
      }.bind(this)
    )
  }

  handlePadding() {
    let alert = $('.alert--fixed')
    let alertHeight = alert.outerHeight()
    $('.banner:first').find('.grid').append(alert)
    $('.header').css({ transition: 'padding-top .3s ease-out' })

    if ($(window).width() > 992) {
      if ($('.banner__header').length) {
        let headingYPos = $('.banner:first h1').offset().top - $('.banner:first .grid').offset().top
        alert.css({ marginTop: `${headingYPos}px` })
      }
    } else {
      $('.header').css({ paddingTop: `${alertHeight}px` })
    }

    $(window).resize(function () {
      if ($(window).width() > 992) {
        if ($('.banner__header').length) {
          let headingYPos =
            $('.banner:first h1').offset().top - $('.banner:first .grid').offset().top
          alert.css({ marginTop: `${headingYPos}px` })
        }
        $('.header').css({ paddingTop: 0 })
      } else {
        alert.css({ marginTop: 0 })
        if ($('.alert--fixed').length) {
          $('.header').css({ paddingTop: `${alertHeight}px` })
        }
      }
    })
  }

  async popup() {
    const url =
      process.env.NODE_ENV === 'development'
        ? '/components/alert/alert.json'
        : 'https://dev.mq.edu.au/squiz-sandbox/patrick/alerts-example/alerts-cct/_nocache'

    const request = await fetch(url)
    const results = await request.json()

    const { message } = results.find(({ asset }) => asset === assetid) || false

    console.log(message)

    if (message) {
      $('body')
        .append(`<div class="grid__col--12 grid__col-md--4 grid__col-md-sta--9 alert alert--support alert--fixed" role="alert" aria-hidden="false">
    	<div class="wrap">
    		<span>${message}</span>
    		<button type="button" class="alert__deactivator icon icon--close">
    			<span class="visually-hidden">Click to close alert</span>
    	</button>
    </div>`)

      this.handlePadding()
      this.handleAlertFixedClose()
    }
  }
}
