export default class InitInfographicPie{

	constructor(){
		// Check if a pie chart exists in the DOM
    if($('.pie').length){
      this.appendCut()
      this.calculateSegments()
			this.addLabels()
    }
	}

  appendCut(){
		// Append cuts within each segment
    $('.pie__segment').each(function(index){
      $(this).append('<div class="cut" id="cut-'+index+'"></div><span></span>')
    })
  }

  calculateSegments(){
    let angles = []
    $('.pie__segment').each(function(index){
      // Get percentage to convert to degrees
      let percent = $(this).data('percent')
      // Calculate sum of degrees so far to position the start of the next segment, start at 0
      let angleSum = angles.reduce((a, b) => a + b, 0)
      // Convert the percent into degrees and push it to the array
      angles.push((percent / 100) * 360)
			// Set starting rotation of segment
			$(this).css('transform', 'rotate('+angleSum+'deg)')
			// Set end degree of cut within segment
      $(this).find('.cut').css('transform', 'rotate('+angles[index]+'deg) scale(1)')
			// Set the same end degree for the animated span
      $(this).find('.cut ~ span').css('transform', 'rotate('+angles[index]+'deg) scale(0.95)')
			// Set animation on mouse over
      $(this).on('mouseover', function(){
        $(this).css('transform', 'rotate('+angleSum+'deg) scale(1.05)')
      }).on('mouseleave', function(){
        $(this).css('transform', 'rotate('+angleSum+'deg) scale(1)')
      })
    })
  }

	addLabels(){
		document.querySelectorAll('.pie__wrapper').forEach((pieEl)=>{
			let totalAngles = 0
			pieEl.querySelectorAll('.pie__segment').forEach((el)=>{
				const percent = parseFloat(el.dataset.percent)
				const angle = percent / 100 * 360
				const desc = document.querySelector('.pie__descriptions')
				totalAngles += angle
				const actualAngle = totalAngles - (angle / 2)
				this.addDescription(pieEl, desc, el, actualAngle)
			})
		})
	}

	addDescription(pieEl, desc, segmentEl, angle){
		const descriptionEl = document.createElement("div")
		descriptionEl.classList.add("pie__description","flex")
		descriptionEl.innerHTML = segmentEl.dataset.description
		desc.appendChild(descriptionEl)
		const {height} = pieEl.getBoundingClientRect()
		this.addPositioningBasedOnAngle(descriptionEl, angle, height)
	}

	pointsOnCircle(radius, angle){
		const x = Math.abs(Math.round(radius + radius * Math.sin(Math.PI * 2 * angle / 360)))
		const y = Math.abs(Math.round(-radius + radius * Math.cos(Math.PI * 2 * angle / 360)))
		return [ x, y ]
	}

	addPositioningBasedOnAngle(el, angle, height){
		const radius = height / 2
		const [ x, y ] = this.pointsOnCircle(radius, angle)
		const padding = 16

		if(angle === 0 || (angle > 0 && angle < 91)) {
			el.style.left = x + padding + "px"
			el.style.bottom = height - y + "px"
			return
		}

		if(angle > 91 && angle < 181) {
			el.style.left = x + padding + "px"
			el.style.top = y + "px"
			return
		}

		if(angle > 181 && angle < 271) {
			el.style.right = height - x + padding + "px"
			el.style.top = y + "px"
			return
		}

		if(angle > 271 && angle < 360) {
			el.style.right = height - x + padding + "px"
			el.style.bottom = height - y + "px"
			return
		}
	}
}
