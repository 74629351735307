/* eslint-disable */
import './lib/jquery-3.4.1.min.js'

/**
 * Import Stylesheet
 */
import '../scss/main.scss'
import './base/icon.font.js'

/**
 * Import Components
 */

//Base Imports
import InitModernizr from './base/modernizr.js'
import InitAccessibility from './base/accessibility.js'
import InitEqualHeights from './base/equal-heights.js'
import InitTextOverflow from './base/text-overflow.js'
import InitTemplates from './base/templates.js'
import InitAnimations from './base/animations.js'
//
////Component Imports
import InitAlert from './components/alert.js'
import InitAccordion from './components/accordion.js'
import InitBanner from './components/banner.js'
import InitButton from './components/button.js'
import InitDropdown from './components/dropdowns/dropdown.js'
// import InitDropdownFilter from './components/dropdowns/dropdown-filter.js';
import InitDropdownMap from './components/dropdowns/dropdown-map.js'
import InitFilter from './components/filter.js'
import InitHeader from './components/header.js'
import InitInput from './components/input.js'

import InitModal from './components/modal.js'
import InitModalSearch from './components/modal-search.js'
import InitPagination from './components/pagination.js'
import InitSearchResults from './components/search-results.js'
import InitSidenavSearch from './components/sidenav-search.js'
import InitSlider from './components/slider.js'
import InitStackableTabs from './components/stackable-tabs.js'
import InitSteps from './components/steps.js'
import InitStories from './components/stories.js'

import InitTab from './components/tab.js'
import InitTable from './components/table.js'
import InitTag from './components/tag.js'
import InitTooltip from './components/tooltip.js'
import InitToggle from './components/toggle.js'
import InitTongue from './components/tongue.js'
import InitVideo from './components/video.js'
import InitVideoGallery from './components/videogallery.js'

import InitBarGraph from './components/infographics/bar-graph.js'
import InitInfographicCircle from './components/infographics/infographic-circle.js'
import InitInfographicPie from './components/infographics/infographic-pie.js'
import InitInfographicRounded from './components/infographics/infographic-rounded.js'
import InitChartDonut from './components/infographics/chart-donut.js'

//Animation Imports

/**
 * Init - initalises all project classes
 */
class Init {
  constructor() {
    //For Dev only
    //		window.$ = window.jQuery = require("jquery");

    //Base Initialisers
    new InitModernizr()
    new InitAccessibility()
    new InitAnimations()

    if ($('.equal-heights').length > 0) {
      new InitEqualHeights()
    }

    new InitButton()

    //Component Initialisers
    new InitAlert()
    new InitAccordion()
    new InitBanner()
    new InitDropdown()
    // new InitDropdownFilter();
    new InitDropdownMap()
    new InitFilter()
    new InitHeader()
    new InitInput()
    new InitModal()
    new InitModalSearch()
    new InitPagination()
    new InitSidenavSearch()
    new InitSearchResults()
    new InitSlider()
    new InitStackableTabs()
    new InitSteps()
    new InitStories()
    new InitTab()
    new InitTable()
    new InitTag()
    new InitTooltip()
    new InitToggle()
    new InitVideo()
    new InitVideoGallery()
    new InitTemplates()

    // if ($('.sub__container h3').length > 0) {
    //   new InitTextOverflow()
    // }

    // if ($('.nav__back').find('span').length > 0) {
    //   new InitTextOverflow()
    // }

    new InitBarGraph()
    new InitInfographicCircle()
    new InitInfographicPie()
    new InitInfographicRounded()
    new InitChartDonut()
  }
}
new Init()
