export default class InitStoriesFilter {
	constructor() {
		this.cache = {
      $storyList: $('.story-list'),
      $story: $('.story-list .story'),
      $noResults: $('.story-list .no-results'),
			$dropdownButton: $('.dropdown[data-type="categories"] button').not('.clear button'),
		}
		if(this.cache.$storyList.length){
			this.selectedItems = []
			this.stories = []
			this.initStoryTags(this.stories, this.selectedItems)
		}
	}

	// Grabs and stores each story name and tags inside an array
	initStoryTags(stories, selectedItems){
		this.cache.$storyList.find('.story').each(function(index){
			let story = {
				dataID: 'story-' + index,
				name: '',
				tags: [],
			}
			story.name = $(this).find('h3, .h3').text()
			$(this).find('.tag').each(function(i){
				story.tags.push($(this).text())
			})
			$(this).attr('data-id', story.dataID)
			stories.push(story)
		})
		this.setListeners(stories, selectedItems)
		this.setCategoryCount(stories)
	}

	setListeners(stories, selectedItems){
		let _this = this
		let currentStories = this.cache.$storyList.find('.story')
		// const defaultButtonText = this.cache.$dropdownButton.find('span').text()
		this.cache.$storyList.find('.dropdown[data-type="categories"] li').on('click', function(){
			let value = $(this).find('input[type=checkbox]').val()
			let defaultButtonText = $(this).closest('.dropdown').find('button span').data('default')
			_this.updateButtonText($(this), defaultButtonText)
			_this.handleCheckbox($(this), stories, selectedItems, value)
			_this.updateResultCount()
			currentStories = _this.cache.$storyList.find('.story:visible')
		})
		this.cache.$storyList.find('.dropdown[data-type="categories"] .clear button').click(function(){
			let defaultButtonText = $(this).closest('.dropdown').find('button span').data('default')
			_this.clearAll($(this), defaultButtonText, selectedItems)
			_this.updateResultCount()
		})
		this.cache.$storyList.find('.input').on('keyup change', function(){
			_this.handleSearchFilter($(this), currentStories)
			_this.updateResultCount()
		})
		this.cache.$storyList.find('.input').on('keypress', function(e){
			const keyPressed = e.keyCode || e.which
			return keyPressed !== 13
		})
		this.cache.$storyList.find('input[type=radio]').on('click', function(){
			let value = $(this).next('label').text()
			// _this.handleRadioFilter(value)
			_this.handleRadio($(this), stories, selectedItems, value)
			_this.updateResultCount()
			currentStories = _this.cache.$storyList.find('.story:visible')
		})
		$('.ico[data-view=content]').on('click', function(){
			_this.toggleListView(false)
		})
		$('.ico[data-view=list]').on('click', function(){
			_this.toggleListView(true)
		})
	}

	setCategoryCount(stories){
		let _this = this
		this.cache.$storyList.find('.dropdown[data-type="categories"] li').each(function(){
			let count = 0
			let $self = $(this)
			$.each(stories, function(index, story){
				if($.inArray($self.find('input[type=checkbox]').val(), story.tags) != -1){
					count++
				}
			})
			$(this).find('label span').text('(' + count + ')')
		})
		let resultCount = this.cache.$storyList.find('.story:visible').length
		let totalStoryCount = this.cache.$storyList.find('.story').length
		this.cache.$storyList.find('span.selected-number').text(resultCount)
		this.cache.$storyList.find('span.total-number').text(totalStoryCount)
	}

	updateButtonText($self, defaultButtonText){
		if($self.find('input[type=checkbox]').length){
			let count = $self.closest('ul').find('input[type=checkbox]:checked').length
			if(count === 1){
				let category = $self.closest('ul').find('li.is-active label p').text()
				$self.closest('.dropdown').find('button span').text(category)
			} else if(count > 1){
				$self.closest('.dropdown').find('button span').text(count + ' categories selected')
			} else {
				$self.closest('.dropdown').find('button span').text(defaultButtonText)
			}
		} else {
			let category = $self.closest('ul').find('li.is-active').text()
			$self.closest('.dropdown').find('button span').text(category)
		}

	}

	handleRadio($self, stories, selectedItems, value){
		if(!$('#all-locations').is(':checked')){
			selectedItems.push(value)
		} else {
			$self.closest('ul').find('input[type=radio]').each(function(){
				let radioValue = $(this).next('label').text()
				$.each(selectedItems, function(index, selectedItem){
					if(radioValue == selectedItem){
						selectedItems.splice(index, 1)
					}
				})
			})
		}
		this.filterResults(stories, selectedItems)
	}

	handleCheckbox($self, stories, selectedItems, value){
		// Add or remove items from selectedItems
		if($self.hasClass('is-active')){
			selectedItems.push(value)
		} else {
			$.each(selectedItems, function(index, selectedItem){
				if(value == selectedItem){
					selectedItems.splice(index, 1)
				}
			})
		}
		this.filterResults(stories, selectedItems)
	}

	// Filter stories based on tags
	filterResults(stories, selectedItems){
		let _this = this
		$.each(stories, function(i, story){
			if(_this.checkValues(selectedItems, story.tags)){
				$('.story[data-id='+story.dataID+']').show()
			} else {
				$('.story[data-id='+story.dataID+']').hide()
			}
		})
		// Show all results when no filters are selected
		if(selectedItems.length === 0){
			this.cache.$story.show()
		}
	}

	// Checks whether selected items match with the current story's tags
	checkValues(selectedItems, currentTags){
		// console.log(selectedItems)
		for(var i = 0; i < selectedItems.length; i++){
			if($.inArray(selectedItems[i], currentTags) == -1){
				return false
			} else {
				return true
			}
		}
	}

  // Update count of results
  updateResultCount(){
    let resultCount = this.cache.$storyList.find('.story:visible').length
    this.cache.$storyList.find('span.selected-number').text(resultCount)
		if(resultCount == 0){
			this.cache.$noResults.fadeIn()
		} else {
			this.cache.$noResults.hide()
		}
  }

	// Remove all filters
	clearAll($self, defaultButtonText, selectedItems){
		this.cache.$storyList.find('.dropdown[data-type="categories"] li input[type=checkbox]').prop('checked', false)
		this.cache.$storyList.find('.dropdown[data-type="categories"] li').removeClass('is-active')
    // Set button text back to default
		$self.closest('.dropdown').find('button span').text(defaultButtonText)
    this.cache.$storyList.find('.story').show()
    // Empty selected items array
    selectedItems.splice(0, selectedItems.length)
	}

	handleSearchFilter($self, currentStories){
		let value = $self.val().toLowerCase()
		currentStories.filter(function(){
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		})
	}

	// Toggle no results
	handleNoResults(){
		if(!$('.story-list .story:visible').length){
			this.cache.$noResults.show()
		} else {
			this.cache.$noResults.hide()
		}
	}

	// Toggle radio buttons
  handleRadioFilter(value){
    let _this = this
    if(value.indexOf("All") >= 0){
      _this.cache.$storyList.find('.story').show()
    } else {
      _this.cache.$storyList.find('.story').filter(function(){
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      })
    }
  }

	toggleListView(setListView){
		if(setListView){
			$('.story-list').addClass('list-view')
		} else {
			$('.story-list').removeClass('list-view')
		}
	}
}
