import { store } from '../store/store.js';

export default class InitAnimations{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
		if($('.fade--up').length){
			this.fadeUp();
		}
		if($('.fade--left').length){
			this.fadeLeft();
		}
	}

    //------------------------------------
    // Fade functions
    //------------------------------------

    fadeUp(){
        let _this = this
        $(document).on('scroll', function(){
            $('.fade--up').each(function(){
                var isInViewport = $(this).isInViewport()
                if($(this).hasClass('fade--repeat')){
                    if(isInViewport){
                        $(this).addClass('is-active')
                    } else {
                        $(this).removeClass('is-active')
                    }
                } else if (isInViewport && !$(this).hasClass('fade--repeat')){
                    $(this).addClass('is-active')
                }
            })
        })
    }

    fadeLeft(){
        let _this = this
        $(document).on('scroll', function(){
            $('.fade--left').each(function(){
                var isInViewport = $(this).isInContainer()
                if($(this).hasClass('fade--repeat')){
                    if(isInViewport){
                        $(this).addClass('is-active')
                    } else {
                        $(this).removeClass('is-active')
                    }
                } else if (isInViewport && !$(this).hasClass('fade--repeat')){
                    $(this).addClass('is-active')
                }
            })
        })
    }

}
