export default class InitSlider {
  constructor() {
    this.setListeners()
    this.setTooltipLocation($('.slider__container').find('input[type=range]'))
    this.setTooltipValue($('.slider__container').find('input[type=range]'))
  }

  setListeners() {
    let _this = this
    $('.slider__container')
      .find('input[type=range]')
      .on('input', function () {
        $(this).closest('.slider-wrap').find('input[type=number]').val($(this).val())
        _this.setBackgroundSize($(this))
        _this.setTooltipValue($(this))
        _this.setTooltipLocation($(this))
      })

    $('.slider-wrap')
      .find('input[type=number]')
      .on('input', function () {
        let sliderRange = $(this).closest('.slider-wrap').find('input[type=range]')
        sliderRange.val($(this).val())
        _this.setBackgroundSize(sliderRange)
        _this.setTooltipValue(sliderRange)
        _this.setTooltipLocation(sliderRange)
      })

    $('.slider__container')
      .find('input[type=range]')
      .on('mouseover', function () {
        $('.slider__tooltip').addClass('is-active')
      })
      .on('mouseout', function () {
        $('.slider__tooltip').removeClass('is-active')
      })
  }

  setBackgroundSize(slider) {
    const min = slider.prop('min')
    const max = slider.prop('max')
    const val = slider.val()
    console.log(((val - min) * 100) / (max - min))
    slider.css('background-size', ((val - min) * 100) / (max - min) + '% 100%')
  }

  setTooltipLocation(slider) {
    console.log('Location')
    const min = slider.prop('min')
    const max = slider.prop('max')
    const val = slider.val()

    const newVal = Number(((val - min) * 100) / (max - min))
    $('.slider__tooltip').css({
      left: `calc(${newVal}% + (${-6 - newVal * 0.15}px))`,
    })
  }

  setTooltipValue(slider) {
    slider.closest('.slider-wrap').find('.slider__tooltip').html(slider.val())
  }
}
