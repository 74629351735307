import { store } from '../store/store.js';
import  '../lib/prettypre.js';

/**
 * InitAccessibility
 */
export default class InitAccessibility{

	constructor(){

		/**
		 * Initalise Class Functions
		 */
		this.handleTabbing();
		this.initPreFormatting();
	}

	/**
	 * @property {Function} initPreFormatting  - Handles code formating of pre tags
	 * @returns void
	 */
	initPreFormatting(){
		store.cache.$pre.prettyPre();
	}


	/**
	 * @property {Function} handleTabbing  - Handles keyboard tabbing styling
	 * @returns void
	 */
	handleTabbing(){
		store.cache.$document.keydown(function(event){
			if( event.which == 9 ) {
				store.cache.$html.addClass('keyboard-user');
			}
		});

		store.cache.$document.mousedown(function(event){
			if( event.which == 9 ) {
				store.cache.$html.removeClass('keyboard-user');
			}
		});
	}
}
