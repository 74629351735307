export default class InitInfographicRounded {
  constructor() {
    if ($('.infographic__rounded').length) {
      this.handleListeners()
    }
  }

  handleListeners() {
    $(window).on(
      'load resize',
      function () {
        const position = $(window).width() >= 768 ? 'absolute' : 'relative'
        this.setLocations(position)
      }.bind(this)
    )
  }

  setLocations(position) {
    $('.infographic__rounded--box').each(function (index) {
      switch (index) {
        case 0:
          $(this).css({
            left:
              `${position}` === 'absolute' ? `calc(50% - ${$(this).outerWidth() / 2}px)` : 'auto',
            top:
              `${position}` === 'absolute' ? `calc(0% - ${$(this).outerHeight() / 2}px)` : 'auto',
          })
          break
        case 1:
          $(this).css({
            right:
              `${position}` === 'absolute' ? `calc(0% - ${$(this).outerWidth() / 2}px)` : 'auto',
            top:
              `${position}` === 'absolute' ? `calc(50% - ${$(this).outerHeight() / 2}px)` : 'auto',
          })
          break
        case 2:
          $(this).css({
            left:
              `${position}` === 'absolute' ? `calc(50% - ${$(this).outerWidth() / 2}px)` : 'auto',
            top:
              `${position}` === 'absolute' ? `calc(100% - ${$(this).outerHeight() / 2}px)` : 'auto',
          })
          break
        case 3:
          $(this).css({
            left:
              `${position}` === 'absolute' ? `calc(0% - ${$(this).outerWidth() / 2}px)` : 'auto',
            top:
              `${position}` === 'absolute' ? `calc(50% - ${$(this).outerHeight() / 2}px)` : 'auto',
          })
          break
        default:
          break
      }
    })
  }
}
